/**
 * 参考文档：http://fabricjs.com/fabric-intro-part-2#text
 * https://github.com/Rookie-Birds/Fabric-Tutorial_zh-CN
 *
 */

// options 字段
let mockOptions = {
    left: 0,
    top: 0,

    fontFamily: null,
    fontSize: null,
    fontWeight: 'normal',       // normal、bold
    stroke: '#c3bfbf',
    strokeWidth: 3,

    underline: false,
    linethrough: false,
    overline: false,
    shadow: null,    // 'rgba(0,0,0,0.2) 0 0 5px'

    textAlign: null,
    lineHeight: null
}
const type = 'XRect'
const defaultOptions = {
    fill: 'white',
    stroke: '#000',
    width: 100,
    height: 100,
    strokeWidth: 1,
    strokeUniform: true,        // 线条的宽度不随着缩放比例的变化而变化
}
const XRect = fabric.util.createClass(fabric.Rect, {
    type,

    /**
     * 初始化
     * @param text
     * @param options
     */
    initialize(options = {}) {
        options = {...defaultOptions, ...options}

        if (!options.id) {
            this.id = `${type}-${new Date().getTime()}`
        }

        this.callSuper('initialize', options);
    },
    /**
     * 转为JSON对象
     */
    toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
            id: this.get('id')
        });
    },
    /**
     * 渲染
     * @param ctx
     * @private
     */
    _render(ctx) {
        this.callSuper('_render', ctx);
    },

});
fabric.XRect = XRect
fabric.XRect.fromObject = (options, callback) => {
    // return fabric.Object._fromObject('LabelRect', object, callback);
    callback && callback(new fabric.XRect( options));

}
export default XRect
