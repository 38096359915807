<template>
    <div>
        <el-form label-width="80px">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="左">
                        <el-input v-model.number="formModel.left" placeholder="左"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="上">
                        <el-input v-model.number="formModel.top" placeholder=""></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="宽">
                        <el-input v-model.number="formModel.width" placeholder=""></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="高">
                        <el-input v-model.number="formModel.height" placeholder=""></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="缩放(X)">
                        <el-input v-model.number="formModel.scaleX" placeholder=""></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="缩放(Y)">
                        <el-input v-model.number="formModel.scaleY" placeholder=""></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="文本内容">
                        <el-input v-model="formModel.text"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="字体大小">
                        <el-input-number v-model="formModel.fontSize" :min="1" :max="100" label="字体大小"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="字体">
                        <el-input v-model="formModel.fontFamily"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="边框粗细">
                        <el-input-number v-model="formModel.strokeWidth" :min="1" :max="100" label="边框粗细"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="边框颜色">
                        <el-color-picker v-model="formModel.stroke" color-format="hex"></el-color-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="角度">
                        <el-input-number v-model="formModel.angle" ></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import {commonParse} from "@/extends/x-label-designer/common/parseElement";
import mixinPanel from "../common/mixinPanel";

import {debounce} from "@/utils/common";
export default {
    name: "LabelTextProperties",
    mixins: [
        mixinPanel
    ],
    data() {
        return {
            formModel: {}
        }
    },
    watch: {
        element() {
            this.initFormModel()
        },
        /*formModel: {
            handler(newValue) {
                console.log('aaaaaaaaaaaaa',newValue)
                debounce(() => {
                    this.updateProperties(this.formModel)
                }, 500)
            },
            // force eager callback execution
            deep: true
        },*/
        'formModel.text'(val) {
            this.updateProperties({
                text: val
            })
        },
        'formModel.fontSize'(val) {
            this.updateProperties({
                fontSize: val
            })
        },
        'formModel.height'(val) {
            this.updateProperties({
                height: val
            })
        },
        'formModel.width'(val) {
            this.updateProperties({
                width: val
            })
        },
        'formModel.strokeWidth'(val) {
            this.updateProperties({
                strokeWidth: val
            })
        },
        'formModel.stroke'(val) {
            this.updateProperties({
                stroke: val
            })
        },
        'formModel.angle'(val) {
            this.updateProperties({
                angle: val
            })
        },
    },
    methods: {
        initFormModel() {
            this.formModel = commonParse(this.element)
        }
    },
    created() {
        this.initFormModel()
    }
}
</script>

<style scoped>

</style>
