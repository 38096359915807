
/**
 * 日期格式化
 * @param {string} fmt  格式，例如 yyyy-MM-dd HH:mm:ss
 */
Date.prototype.format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1,                 //月份
        "d+": this.getDate(),                    //日
        "h+": this.getHours(),                   //小时
        "H+": this.getHours(),                   //小时
        "m+": this.getMinutes(),                 //分
        "s+": this.getSeconds(),                 //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds()             //毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}


/**
 * 获取指定时间的周一
 * @param {Date} date
 */
function getFirstDayOfWeek(date) {

    var weekday = date.getDay() || 7; //获取星期几,getDay()返回值是 0（周日） 到 6（周六） 之间的一个整数。0||7为7，即weekday的值为1-7

    date.setDate(date.getDate() - weekday + 1);//往前算（weekday-1）天，年份、月份会自动变化
    return date;
}

/**
 * 获取指定时间的周日
 * @param {Date} date
 */
function getLastDayOfWeek(date) {

    var weekday = date.getDay() || 7; //获取星期几,getDay()返回值是 0（周日） 到 6（周六） 之间的一个整数。0||7为7，即weekday的值为1-7

    date.setDate(date.getDate() + (7 - weekday));//往后算（7 - weekday ）天，年份、月份会自动变化
    return date;
}

/**
 * 获取指定时间当月的第一天
 * @param {Date} date
 */
function getFirstDayOfMonth(date) {
    date.setDate(1);
    return date;
}

/**
 * 获取指定时间当月的最好一天
 * @param {Date} date
 */
function getLastDayOfMonth(date) {
    date.setMonth(date.getMonth() + 1);
    date.setDate(0)
    return date;
}


/**
 * 获取指定时间的当季第一天
 * @param {Date} date
 */
function getFirstDayOfSeason(date) {
    var month = date.getMonth();
    if (month < 3) {
        date.setMonth(0);
    } else if (2 < month && month < 6) {
        date.setMonth(3);
    } else if (5 < month && month < 9) {
        date.setMonth(6);
    } else if (8 < month && month < 11) {
        date.setMonth(9);
    }
    date.setDate(1);
    return date;
}

/**
 * 获取指定时间的当季最后一天
 * @param {Date} date
 */
function getLastDayOfSeason(date) {
    var month = date.getMonth();
    if (month < 3) {
        date.setMonth(3);
    } else if (2 < month && month < 6) {
        date.setMonth(6);
    } else if (5 < month && month < 9) {
        date.setMonth(9);
    } else if (8 < month && month < 11) {
        date.setYear(date.getFullYear() + 1)
        date.setMonth(0);
    }
    date.setDate(0);
    return date;
}

/**
 * 获取指定时间的当年第一天
 * @param {Date} date
 */
function getFirstDayOfYear(date) {
    date.setDate(1);
    date.setMonth(0);
    return date
}

/**
 * 获取指定时间的当年最后一天
 * @param {Date} date
 */
function getLastDayOfYear(date) {
    date.setYear(date.getFullYear())
    date.setMonth(11);
    date.setDate(31);
    return date
}

/**
 * 将日期字符串从一种类型转换为另一种类型
 * @param {string} dateStr
 * @param {string} pattern
 */
function formatToAnotherPattern(dateStr,pattern){
    if(dateStr === undefined || dateStr === null || dateStr.trim() === ""){
        return ""
    }
    if(pattern === undefined || pattern === null || pattern.trim() === ""){
        return ""
    }
    return new Date(dateStr).format(pattern)
}


function addDays(dayNumber, date = new Date()) {
    date = date ? date : new Date();

    let ms = dayNumber * (1000 * 60 * 60 * 24)

    let newDate = new Date(date.getTime() + ms);

    return newDate
}

const dates = {
    addDays,

    getFirstDayOfWeek,
    getLastDayOfWeek,
    getFirstDayOfMonth,
    getLastDayOfMonth,
    getFirstDayOfSeason,
    getLastDayOfSeason,
    getFirstDayOfYear,
    getLastDayOfYear,
    formatToAnotherPattern,
    pickerOptions: {
        // 时间区间
        timeInterval: {
            shortcuts: [
                {
                    text: '今天', onClick(picker) {
                        const end = new Date(new Date().toLocaleDateString() + " 23:59:59");
                        const start = new Date(new Date().toLocaleDateString() + " 00:00:00");
                        picker.$emit('pick', [start, end]);
                    }
                },{
                text: '最近7天', onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                }
            }, {
                text: '最近30天', onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                }
            }, {
                text: '最近90天', onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                }
            }]
        },
        date: {    //日期快捷选择项
            disabledDate(time) {
                return time.getTime() > Date.now();
            },
            shortcuts: [{
                text: '今天',
                onClick(picker) {
                    picker.$emit('pick', new Date());
                }
            }, {
                text: '昨天',
                onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 24 * 1000);
                    picker.$emit('pick', date);
                }
            }]
        },
        futureDate: {    // 未来的时间选择
            disabledDate(time) {
                return time.getTime() < Date.now();
            },
            shortcuts: [{
                text: '今天',
                onClick(picker) {
                    picker.$emit('pick', new Date());
                }
            },{
                text: '本周日',
                onClick(picker) {
                    picker.$emit('pick', getLastDayOfWeek(new Date()));
                }
            },{
                text: '本月底',
                onClick(picker) {
                    picker.$emit('pick', getLastDayOfMonth(new Date()));
                }
            }]
        }
    }
}

export default dates
