import QRCode from 'qrcode'

/*https://www.npmjs.com/package/deasync*/

const type = 'XQrCode'

let defaultOptions = {
    errorCorrectionLevel: 'M', margin: 2, width: 128
}
const generateQrCode = (options) => {
    options = {...defaultOptions, ...options}


    let {text = ''} = options
    let {errorCorrectionLevel, margin, width} = options

    return new Promise((resolve, reject) => {
        QRCode.toDataURL(text, {errorCorrectionLevel, margin, width}, (err, url) => {
            if (err) {
                return reject(err)
            }
            fabric.Image.fromURL(url, (image) => {
                resolve({url, image, element: image._element})
            })
        })
    })
}

const XQrCode = fabric.util.createClass(fabric.Image, {
    type,

    /**
     * 初始化
     * @param options
     */
    initialize(options = {}) {

        options = {...defaultOptions, ...options}

        let {text = ''} = options
        let {errorCorrectionLevel, margin, width} = options

        this.set({
            errorCorrectionLevel, margin, text
        })
        if (!options.id) {
            this.id = `${type}-${new Date().getTime()}`
        }

        this.callSuper('initialize', options.element, options);
    },
    /**
     * 转为JSON对象
     */
    toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
            errorCorrectionLevel: this.get('errorCorrectionLevel'),
            margin: this.get('margin'),
            text: this.get('text'),
            id: this.get('id')
        });
    },
    /**
     * 渲染
     * @param ctx
     * @private
     */
    _render(ctx) {
        // if(this._image_loaded) {
        this.callSuper('_render', ctx);
        // }
    },


    reload() {
        let {text = ''} = this
        if (text == null || text == undefined) {
            return
        }
        generateQrCode(this).then(({url,image}) => {
            this.setSrc(url)
        })
    }
});
fabric.XQrCode = XQrCode
fabric.XQrCode.generateQrCode = generateQrCode

fabric.XQrCode.fromObject = (options, callback) => {
    // return fabric.Object._fromObject(type, object, callback);

    generateQrCode(options).then(({url, image, element}) => {
        options.element = element
        callback && callback(new fabric.XQrCode(options));
    })

}

export default XQrCode
