const type = 'XLine'

const defaultOptions = {
    stroke: '#000',
    strokeWidth: 1,

    /*当线条的strokeWidth较小时，确实比较难以选中。为了解决这个问题，可以考虑在线条周围增加一些透明度较高的padding，以便更容易被选中。
    * 设置padding值时，可以考虑根据线条的strokeWidth来进行动态计算，以确保适合不同线条宽度的情况。这样做可以在一定程度上提高线条的可选中性，让用户更方便地进行操作。
    * */
    padding: 1 * 2  // padding值可以根据具体情况进行调整
}
const XLine = fabric.util.createClass(fabric.Line, {
    type,

    /**
     * 初始化
     * @param options
     */
    initialize(points = [], options = {}) {
        options = {...defaultOptions, ...options}
        let {x0, y0, x1, y1} = options
        if (!options.id) {
            this.id = `${type}-${new Date().getTime()}`
        }
        this.callSuper('initialize', points, options);
    },
    /**
     * 转为JSON对象
     */
    toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
            /*x0: this.get('x0'),
            y0: this.get('y0'),
            x1: this.get('x1'),
            y1: this.get('y1'),*/
            id: this.get('id')
        });
    },
    /**
     * 渲染
     * @param ctx
     * @private
     */
    _render(ctx) {
        this.callSuper('_render', ctx);
    },

});
fabric.XLine = XLine
fabric.XLine.fromObject = (options, callback) => {
    let {left, top, width, height} = options
    let points = [left, top, left + width, top + height]
    callback && callback(new fabric.XLine(points, options));

}
export default XLine
