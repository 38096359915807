<template>
    <div>
        <div style="height: 10px"></div>

        <el-card class="box-card" :body-style="{ paddingBottom: '10px' }">
            <el-row class="upload-operate">
                <el-col :span="16">
                    <el-input v-model="formModel.url" placeholder="输入图片地址">
                        <el-button slot="append" icon="el-icon-search" @click="handleShowFromUrl"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="8">
                    <span class="text">或</span>
                    <!--                    <el-button type="primary" icon="el-icon-upload" @click="handleOpenChooseFile">上传图片</el-button>
                                        <input ref="fileinput" type="file" id="fileinput" style="display: none;"
                                               accept="image/jpeg,image/gif,image/png,image/jpg" @change="handleChoosedFile"/>-->
                    <x-file-button ref="xFileButton" title="打开文件" accept="image/jpeg,image/gif,image/png,image/jpg"
                                   @change="handleChoosedFile"></x-file-button>
                </el-col>
            </el-row>
        </el-card>
        <div class="block"></div>

        <el-row :gutter="20">
            <el-col :span="16">
                <el-card class="box-card" :body-style="{ paddingBottom: '10px', height: '560px' }">
                    <div slot="header" class="tools-wrapper">
                        <!--                        <el-button-group>
                                                    <el-button icon="el-icon-zoom-in" @click="handleZoomOut">放大</el-button>
                                                    <el-button icon="el-icon-zoom-out" @click="handleZoomIn">缩小</el-button>
                                                </el-button-group>-->

                        <el-radio-group v-model="formModel.showMode" @input="showOcrResult">
                            <el-radio-button label="原图"></el-radio-button>
                            <el-radio-button label="结果"></el-radio-button>
                            <el-radio-button label="识别对比"></el-radio-button>
                        </el-radio-group>
                        <el-divider direction="vertical"></el-divider>
                        <el-button icon="el-icon-brush" @click="$refs.xFabricInstance.clear()">清除画布</el-button>
                        <el-button icon="el-icon-help" @click="handleRecognitionDemo">识别演示</el-button>

                    </div>
                    <div class="image-wrapper full-width" id="image-wrapper" v-loading="loading">
                        <x-fabric ref="xFabricInstance" :width="canvasInfo.width" :height="canvasInfo.height"></x-fabric>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card class="box-card" :body-style="{ paddingBottom: '10px', height: '560px' }">
                    <div slot="header" class="tools-wrapper">
                        <!--                        <span>全文还原</span>-->
                        <el-radio-group v-model="formModel.tabIndex">
                            <el-radio-button label="控制中心"></el-radio-button>
                            <el-radio-button label="全文还原"></el-radio-button>
                            <el-radio-button label="JSON返回"></el-radio-button>
                            <el-radio-button label="历史记录"></el-radio-button>
                            <el-radio-button label="其他"></el-radio-button>
                        </el-radio-group>
                    </div>

                    <div class="content-wrapper">
                        <div v-show="formModel.tabIndex == '控制中心'" class="item">
                            <control-center :image-data="this.imageData"
                                            @recognition-success="handleRecognitionSuccess"></control-center>
                        </div>
                        <div v-show="formModel.tabIndex == '全文还原'" class="item">
                            <div style="height: 500px; border: 1px solid  #409EFF; padding: 5px">
                                {{ ocrResult ? ocrResult.content : '' }}
                            </div>
                            <div
                                style="display: flex;border: 1px solid #409EFF; border-top: 0; justify-content: center; align-items: center; padding: 5px">
                                <el-button type="text"
                                           @click="(e) => $clipboard(ocrResult ? ocrResult.content : '', e)"
                                           :disabled="ocrResult == null">一键复制
                                </el-button>
                            </div>
                        </div>
                        <div v-show="formModel.tabIndex == 'JSON返回'" class="item">
                            <vue-json-editor v-model="ocrResult" :show-btns="false" :expandedOnStart="true"
                                             lang="zh"></vue-json-editor>
                        </div>
                        <div v-show="formModel.tabIndex == '历史记录'" class="item">
                            <history :list="historyResult"></history>
                        </div>
                        <div v-show="formModel.tabIndex == '其他'" class="item">
                            <div>图片宽高：{{ imageInfo.width }} / {{ imageInfo.height }} px</div>
                            <div>容器高度：{{ canvasInfo.width }} / {{ canvasInfo.height }} px</div>
                            <!--                            <div>缩放比例：{{ canvasInfo.scaleX.toFixed(2) }} / {{ canvasInfo.scaleY.toFixed(2) }}</div>-->
                            <el-divider></el-divider>
                            <div>
                                <el-link type="primary"
                                         href="https://duguang.aliyun.com/experience?type=universal&subtype=general_text#intro"
                                         target="_blank">Ali OCR Demo
                                </el-link>
                                <el-divider direction="vertical"></el-divider>
                                <el-link type="primary" href="https://help.aliyun.com/document_detail/270960.html"
                                         target="_blank">Ali OCR Doc
                                </el-link>
                                <el-divider direction="vertical"></el-divider>
                                <el-link type="primary" href="https://next.api.aliyun.com/api/ocr-api"
                                         target="_blank">Ali OCR Api Debug
                                </el-link>


                            </div>
                            <el-divider></el-divider>
                            <div>
                                <el-link type="primary" href="https://c.runoob.com/front-end/854/"
                                         target="_blank">正则表达式在线测试
                                </el-link>

                            </div>
                        </div>
                        <!--                         [\u4e00-\u9fa5]{0,1} [\u4e00-\u9fa5]{1,} [\d]{18} [\d]{11} [\d]{19} [\u4e00-\u9fa5]{0,}-->


                    </div>

                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>

// https://www.runoob.com/tags/ref-canvas.html
// http://fabricjs.com/docs/fabric.Canvas.html#backgroundImage


import vueJsonEditor from 'vue-json-editor'
import ControlCenter from "./ocr/ControlCenter";
import History from "./ocr/History";

export default {
    name: "Ocr",
    components: {
        History,
        ControlCenter,
        vueJsonEditor
    },
    data() {
        return {
            formModel: {
                url: '',
                showMode: '识别对比',
                tabIndex: '控制中心'
            },
            loading: false,
            image: null,
            imageData: '',

            canvasEventHandler: {},

            minScale: 0.5,
            maxScale: 5,
            currentScale: 1,

            imageInfo: {
                width: 0,
                height: 0
            },
            canvasInfo: {
                scaleX: 1,
                scaleY: 1,
                height: 560,
                width: 560,
            },

            ocrResult: null,
            historyResult: []
        }
    },
    methods: {

        loadImage(imageData) {
            return new Promise((resolve, reject) => {
                /*const image = new Image()
                image.src = imageData
                image.setAttribute('crossOrigin', 'anonymous') // 允许跨域访问
                image.onload = () => {
                    resolve(image)
                }*/

                fabric.Image.fromURL(imageData, (img, error) => {
                    if (error) {
                        return reject(error)
                    }
                    resolve(img)
                });
            })
        },
        resetData() {
            this.loading = false
            this.image = null
            this.imageData = null
            this.imageInfo = {
                width: 0,
                height: 0
            }

            this.canvasInfo = {
                scaleX: 1,
                scaleY: 1,
                height: 560,
                width: 560,
            }

            let imageWrapper = document.getElementById('image-wrapper')
            if (imageWrapper) {
                this.$set(this.canvasInfo, 'width', imageWrapper.offsetWidth)
                this.$set(this.canvasInfo, 'height', imageWrapper.offsetHeight)
                this.getFabricCanvas().setWidth(imageWrapper.offsetWidth)
                this.getFabricCanvas().setHeight(imageWrapper.offsetHeight)
            }


        },
        handleShowFromUrl() {
            let {url} = this.formModel
            if (this.$strings.isBlank(url)) {
                return
            }
            this.resetData()

            this.loading = true
            this.loadImage(url).then(image => {
                this.image = image
                this.imageData = image.toDataURL()
                this.$refs.xFabricInstance.clear()
                this.setBackgroundImage()

                // 加载图片时只显示背景
            }).finally(() => {
                this.loading = false
            })
        },
        handleChoosedFile(file) {
            if (file.size > 10 * 1024 * 1024) {
                this.$message.error('单个文件大小不能超过 10M!')
                return
            }

            this.resetData()
            this.$refs.xFabricInstance.clear()

            let that = this
            this.$refs.xFileButton.readAsDataURL().then((base64Url) => {
                this.loading = true
                this.loadImage(base64Url).then(image => {
                    this.image = image
                    this.imageData = image.toDataURL()

                    this.setBackgroundImage()

                    // 加载图片时只显示背景
                }).finally(() => {
                    this.loading = false
                })
            });
        },
        handleRecognitionSuccess(res) {
            this.ocrResult = res
            this.showOcrResult()

            // 保存到history
            this.historyResult.push(res)
        },
        handleRecognitionDemo() {
            this.resetData()
            this.loading = true
            this.loadImage('/static/ocr/demo.jpg').then(image => {
                this.image = image
                this.imageData = image.toDataURL()
                this.$refs.xFabricInstance.clear()

                this.ocrResult = JSON.parse(JSON.stringify(require('./ocr/demo.json').data))

                this.showOcrResult()
                // 加载图片时只显示背景
            }).finally(() => {
                this.loading = false
            })
        },
        setBackgroundImage() {
            if (!this.image) {
                return
            }

            // 将canvas的width和height设置成图片的原始width,height
            let image = this.image
            let imageWidth = image.width
            let imageHeight = image.height

            // 如果有OCR结果，取结果的宽高
            if (this.ocrResult) {
                imageWidth = this.ocrResult.width
                imageHeight = this.ocrResult.height
            }

            this.$set(this.imageInfo, 'width', imageWidth)
            this.$set(this.imageInfo, 'height', imageHeight)

            // 将图片放置在外部容器中
            let scaleX = this.canvasInfo.width / imageWidth
            let scaleY = this.canvasInfo.height / imageHeight

            /*this.$set(this.canvasInfo, 'scaleX', scaleX)
            this.$set(this.canvasInfo, 'scaleY', scaleY)*/

            this.$refs.xFabricInstance.setBackgroundImage({
                image,
                width: imageWidth,
                height: imageHeight,
                /*scaleX,
                scaleY*/
            })
        },
        showOcrResult() {
            this.$refs.xFabricInstance.clear()
            if (!this.ocrResult) {
                return
            }

            let {showMode} = this.formModel

            if (showMode == '原图') {
                this.setBackgroundImage()
            } else if (showMode == '结果') {
                this.showWords()
            } else {
                this.setBackgroundImage()
                this.showWords()
            }

            /*this.savedposition = this.$refs.xFabricInstance.toJson(['backgroundImage']);
            console.log(this.savedposition)*/

        },
        showWords() {
            // canvas.selection = false; // disable group selection
            if (this.ocrResult == null) {
                return
            }
            let {content, prism_wordsInfo} = this.ocrResult
            this.$set(this.imageInfo, 'content', content)

            let {scaleX, scaleY} = this.canvasInfo

            let calculateFontSize = (width, height, content) => {
                let area = width * height;
                let contentLength = content.length;

                return Math.sqrt(area / contentLength); //this provides the font-size in points.
            }
            prism_wordsInfo.forEach(wordInfo => {
                let {angle, direction, height, width, pos = [], prob, word, x, y} = wordInfo
                if (this.$strings.isBlank(word)) {
                    return
                }

                // angle  文字块的角度，这个角度只影响width和height，当角度为-90、90、-270、270，width和height的值需要自行互换
                if (angle == -90 || angle == 90 || angle == -270 || angle == 270) {
                    let tmp = height
                    height = width
                    width = tmp
                }

                let options = {
                    left: Math.ceil(scaleX * pos[0].x),
                    top: Math.ceil(scaleY * pos[0].y),
                    width: scaleX * width,
                    height: scaleY * height,
                    fontSize: 15,
                    opacity: 1,

                    fill: '#0470FE',
                    selectable: true,   // make object unselectable
                    // scaleX,scaleY
                }

                // options.fontSize =   options.width /  word.length * 0.8
                options.fontSize = Math.floor(calculateFontSize(options.width, options.height, word))
                // options.fontSize =  Math.max(Math.min(options.width / (1*20), parseFloat(1/0)), parseFloat(-1/0))

                /* pos.forEach(p => {
                     p.x *= scaleX;
                     p.y *= scaleY
                 })*/
                /*let path = new fabric.Path(`M ${pos[0].x} ${pos[0].y} L ${pos[1].x} ${pos[1].y} L ${pos[2].x} ${pos[2].y} L ${pos[3].x} ${pos[3].y} z`);
                path.set({fill: 'white', stroke: 'green', opacity: 0, centeredRotation:true});
                options.path = path*/

                this.$refs.xFabricInstance.createTextbox(word, options)
            })
        },
        handleShowModeChange() {
            this.showOcrResult()
        },

        handleZoomOut() {
            let canvas = this.getFabricCanvas()
            let newZoom = canvas.getZoom() + 0.25
            if (newZoom > 6) {
                newZoom = 6
            }
            canvas.zoomToPoint(this.getCenterPoint(), newZoom);
        },
        handleZoomIn() {
            let canvas = this.getFabricCanvas()
            let newZoom = canvas.getZoom() - 0.25
            if (newZoom < 0.1) {
                newZoom = 0.1
            }
            canvas.zoomToPoint(this.getCenterPoint(), newZoom);
            /*
             let scale = this.currentScale - 0.25
             if(scale < this.minScale) {
                 scale = this.minScale
             }
             this.$refs.xFabricInstance.scale(scale)*/
        },
        handleRestore() {
            // 中心点位置
            this.$refs.xFabricInstance.loadFromJSON(this.savedposition);

            this.setBackgroundImage().then(() => {
                this.getFabricCanvas().zoomToPoint(this.getCenterPoint(), 1);
            })
        },
        getCenterPoint() {
            let x = this.canvasInfo.width / 2
            let y = this.canvasInfo.height / 2
            return {x, y}
        },

        getFabricCanvas() {
            let {canvas} = this.$refs.xFabricInstance
            return canvas
        }
    },
    mounted() {
        let imageWrapper = document.getElementById('image-wrapper')
        let canvasWidth = imageWrapper.offsetWidth
        let canvasHeight = imageWrapper.offsetHeight

        this.$set(this.canvasInfo, 'width', canvasWidth)
        this.$set(this.canvasInfo, 'height', canvasHeight)

        this.$nextTick(() => {
            this.getFabricCanvas().setWidth(canvasWidth)
            this.getFabricCanvas().setHeight(canvasHeight)
        })
    }
}
</script>

<style lang="scss" scoped>
.upload-operate {
    .text {
        font-size: 12px;
        color: #3d3d3d;
        letter-spacing: .62px;
        padding: 0 10px;
        line-height: 32px;
    }
}

.image-wrapper {


}

.content-wrapper {
    word-break: break-all;
    height: 100%;

    .item {
        height: 100%;
        overflow-y: auto;
    }


}

::v-deep .jsoneditor-vue {
    height: 550px;
}

::v-deep .json-save-btn {
    margin-top: 10px;
    cursor: pointer;
    width: 100px;
    line-height: 30px;
    font-size: 14px;
}
</style>
