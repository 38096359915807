<template>
    <div class="x-label-designer">

        <div class="toolbar-container">
            <el-button @click="$refs.xFabricInstance.toTopLayer()"> 置于顶层</el-button>
            <el-button @click="$refs.xFabricInstance.toLastLayer()"> 置于底层</el-button>
            <el-button @click="$refs.xFabricInstance.toUpLayer()"> 上移一层</el-button>
            <el-button @click="$refs.xFabricInstance.toDownLayer()"> 下移一层</el-button>
            <el-divider direction="vertical"></el-divider>

            <el-button @click="$refs.xFabricInstance.group()"> 组合</el-button>
            <el-button @click="$refs.xFabricInstance.ungroup()"> 取消组合</el-button>
            <el-divider direction="vertical"></el-divider>

            <el-button @click="downloadToJson"> 保存为JSON</el-button>
            <x-file-button ref="loadFromJsonButton" @change="_handleLoadFromJson"
                           title="从JSON加载"></x-file-button>
            <el-divider direction="vertical"></el-divider>

            <el-button @click="(e) => copyCpclCommand(e)"> 复制为CPCL指令</el-button>
        </div>
        <div class="block"></div>
        <div class="main-container">
            <div class="palette-container">
                <div class="group">
                    <div draggable="true" class="entry" @click="_handleAddLabel" title="文本框">
                        <x-icon name="wenben" :size="30"></x-icon>
                    </div>
                    <div draggable="true" class="entry" @click="_handleAddRect" title="矩形">
                        <x-icon name="juxing" :size="30"></x-icon>
                    </div>
                    <div draggable="true" class="entry" @click="_handleAddLine" title="线条">
                        <x-icon name="xiantiao" :size="30"></x-icon>
                    </div>
                    <div draggable="true" class="entry" @click="_handleAddBarCode" title="条形码">
                        <x-icon name="bar-code" :size="30"></x-icon>
                    </div>
                    <div draggable="true" class="entry" @click="_handleAddQrCode" title="二维码">
                        <x-icon name="erweima" :size="30"></x-icon>
                    </div>
                </div>
            </div>
            <div class="canvas-container">
                <x-fabric ref="xFabricInstance" :width="width" :height="height" :allow-zoom="false"
                          :allow-move="false"  allow-delete
                          v-on="canvasEventHandler"></x-fabric>
            </div>
            <div class="layer-container">
                <div>图层信息({{ objects.length }})</div>

                <div>
                    <el-tag
                        v-for="tag in objects"
                        :key="tag.id"
                        :closable="true"
                        size="medium"
                        @click="_handleSelectedObject(tag)"
                        @close="_handleRemoveSelected(tag)">
                        {{ tag.id }}
                    </el-tag>
                </div>
            </div>
            <div class="properties-container">
                <PropertiesPanel :canvas="canvas" :element="currentObj" v-if="currentObj != null"></PropertiesPanel>
            </div>
        </div>
    </div>
</template>

<script>
import {XText, XLine, XRect, XBarCode, XQrCode,XRuler} from "@/extends/x-label-designer/objects";

import PropertiesPanel from "@/extends/x-label-designer/propertiesPanel/index.vue";
import {debounce, download} from '@/utils/common'
import cpcl from "@/utils/print/language/cpcl";
// import XQRCodeShape from "@/extends/x-label-designer/objects/XQRCodeShape";

export default {
    name: "XLabelDesigner",
    components: {
        PropertiesPanel
    },
    props: {
        id: {
            type: String,
            required: false,
            default: 'fabricCanvas'
        },
        width: {
            type: [Number, String],
            required: true,
            default: '100%'
        },
        height: {
            type: [Number, String],
            required: true,
            default: 200
        },
    },
    data() {
        let that = this


        let eventNames = ['mouse:down', 'object:moving', 'selection:created', 'selection:updated', 'object:scaling']
        let canvasEventHandler = {}

        let debounceFn = debounce((options) => {
            let {target} = options
            that.currentObj = null
            that.$nextTick(() => {
                that.currentObj = target
            })
        }, 200, false)

        eventNames.forEach(key => {
            canvasEventHandler[key] = debounceFn
        })

        canvasEventHandler['object:added'] = ({target}) => {
            this.objects.push(target)
        }
        canvasEventHandler['object:removed'] = ({target}) => {
            let index = this.objects.findIndex(o => o.id == target.id)
            if (index != -1) {
                this.objects.splice(index, 1)
            }
        }

        canvasEventHandler['object:moving'] = ({target}) => {
            target.opacity = 0.5;

        }
        canvasEventHandler['object:modified'] = ({target}) => {
            target.opacity = 1
        }


        return {
            canvasEventHandler,
            currentObj: null,

            canvas: null,

            objects: []

        };
    },
    methods: {
        createNew(options = {width: 300, height: 300}) {
            this._getCanvas().setDimensions(options)

            this.objects = []

        },
        clear() {
            this.$refs.xFabricInstance.clear()
            this.objects = []
        },
        _getCanvas() {
            return this.$refs.xFabricInstance.canvas
        },
        _addObject(object) {
            let canvas = this._getCanvas()
            canvas.add(object);
            canvas.setActiveObject(object);
            // this.currentObj = object
            canvas.requestRenderAll()
        },
        _handleAddLabel() {
            let labeledRect = new XText('hello world', {
                width: 100,
                height: 100,
                left: 100,
                top: 100,
                fontSize: 20,
                fontFamily: '30px Helvetica',
            });

            this._addObject(labeledRect)
        },
        _handleAddRect() {

            let labeledRect = new XRect({
                left: 100,
                top: 100,

                // strokeUniform: true
            });

            this._addObject(labeledRect)

        },
        _handleAddLine() {
            let line = new XLine([100, 50, 220, 50], {
                strokeUniform: true
            });

            this._addObject(line)

        },
        _handleAddBarCode() {
            let text = '0123456789'

            XBarCode.generateBarCode({text}).then(({image, element}) => {
                let barCode = new XBarCode({
                    top: 50,
                    left: 50,
                    text,
                    element
                });
                this._addObject(barCode)
            })
        },
        _handleAddQrCode() {
            let text = '二维码内容'
            XQrCode.generateQrCode({text}).then(({image, element}) => {
                let labeledRect = new XQrCode({
                    top: 50,
                    left: 50,
                    text,
                    element
                });
                this._addObject(labeledRect)
            })


            /*let shape = new XQRCodeShape(this.canvas,{
                top: 150,
                left: 50,
                text,
            })
            shape.text = 'hello world'
            shape.create()*/
        },
        downloadToJson() {
            let json = this.$refs.xFabricInstance.toJson()

            download(new Date().getTime() + '.json', JSON.stringify(json))
        },
        _handleLoadFromJson() {
            this.$refs.loadFromJsonButton.readAsText().then((result) => {
                this.$refs.xFabricInstance.loadFromJSON(JSON.parse(result))

            })
        },
        copyCpclCommand(e) {
            let json = this.$refs.xFabricInstance.toJson(true)

            let {width, height, objects = []} = json

            cpcl.creatNewPage(width, height)

            objects.forEach(o => {
                this._buildCpclCommand(cpcl, o)
            })
            cpcl.addPrint()

            this.$clipboard(cpcl.toCommandText(), e)
        },
        _buildCpclCommand(cpcl, object) {
            let {left, top, width, height, type, text, scaleX = 1, scaleY = 1, angle = 0} = object
            if (type == 'group') {
                let {objects} = object
                objects.forEach(o => {
                    this._buildCpclCommand(cpcl, o)
                })
                return
            }
            if(angle == 0) {
                let oldWidth = width
                width = height * scaleX
                height = oldWidth * scaleY
            }else if(angle == 90){

            }

            if (type == 'XText') {
                cpcl.addText(left, top, text)
            } else if (type == 'XRect') {
                cpcl.addBox(left, top, left + width, top + height)
            } else if (type == 'XQrCode') {
                cpcl.addQrCode(left, top, text)
            } else if (type == 'XBarCode') {
                cpcl.addBarCode(left, top, text, 1, height)
            } else if (type == 'XLine') {
                cpcl.addLine(object.x1, object.y1, object.x2, object.y2, width)
            }
        },
        _handleRemoveSelected(object) {
            this._getCanvas().remove(object)
            this._getCanvas().requestRenderAll()
        },
        _handleSelectedObject(object) {
            this._getCanvas().setActiveObject(object)
            this._getCanvas().requestRenderAll()
        }
    },
    mounted() {
        this.$refs.xFabricInstance.addDeleteControl()

        this.canvas = this._getCanvas()

    }
}
</script>

<style lang="scss" scoped>
.x-label-designer {
    position: relative;

    .main-container > div[class*='-container'] {
        display: inline-block;
        vertical-align: top;
        border: 1px solid deepskyblue;
    }

    .main-container > div[class*='-container']:not(:first-child) {
        margin-left: 10px;
    }

    .palette-container {
        width: 46px;
        /*min-height: 200px;*/
        //background: #F7F7F7;
        //border: solid 1px #BFBFBF;
        //border-radius: 2px;

        .entry {
            display: inline-block;

            line-height: 46px;
            text-align: center;
            width: 46px;
            cursor: pointer;
        }

        .entry:not(:last-child) {
            border-bottom: 1px solid deepskyblue;
        }
    }

    .canvas-container {

    }

    .layer-container {
        width: 200px;
        height: 500px;
        padding: 10px;
        overflow: scroll;

        ::v-deep .el-tag:not(:first-child) {
            margin-top: 10px;
        }
    }

    .properties-container {
        padding: 10px;
        min-width: 400px;
        min-height: 300px;
    }

    .toolbar-container {
        .entry {
            display: inline-block;
            border: 1px solid deepskyblue;
            line-height: 46px;
            padding: 5px;
            text-align: center;
            width: 46px;
            cursor: pointer;
        }
    }

    /*.palette-container {
        width: 100px;
        min-height: 200px;
        position: absolute;
        left: 20px;
        top: 20px;
        box-sizing: border-box;
        background: #F7F7F7;
        border: solid 1px #BFBFBF;
        border-radius: 2px;
        z-index: 10000000;


        !*.entry {
            width: 46px;
            height: 46px;
            line-height: 46px;
            cursor: default;
        }*!

        .entry {
            border: 1px solid deepskyblue;
            line-height: 46px;
            padding: 5px;
            text-align: center;
        }
    }*/


}
</style>
