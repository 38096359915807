<template>
    <div>
        <div style="height: 10px"></div>
        <el-card class="box-card" :body-style="{ paddingBottom: '10px' }">

            <el-button @click="$refs.designer.createNewDiagram()" icon="el-icon-notebook-1">新建</el-button>
            <x-file-button ref="xFileButton" title="打开文件" accept="application/xml"
                           @change="handleCreateFromFile"></x-file-button>
            <el-divider direction="vertical"></el-divider>

            <el-button @click="$refs.designer.toSVG()" icon="el-icon-download">下载为SVG</el-button>
            <el-button @click="$refs.designer.toXML()" icon="el-icon-download">下载为XML</el-button>
            <el-divider direction="vertical"></el-divider>

            <el-button @click="handleXmlPreview" icon="el-icon-view">XML预览</el-button>
            <el-button @click="$refs.designer.toggleMiniMap()" icon="el-icon-view">显隐小地图</el-button>
            <!--            <el-button @click="handleCreateFromFile" icon="el-icon-view">打开文件</el-button>-->

            <el-button @click="$message.success(JSON.stringify($refs.designer.getProcess()))" icon="el-icon-view">流程信息</el-button>

        </el-card>
        <div class="block"></div>

        <el-card class="box-card" :body-style="{ paddingBottom: '10px' }">
            <x-bpmn-designer ref="designer" :height="620"></x-bpmn-designer>
        </el-card>

        <el-dialog
            title="XML预览"
            :visible.sync="xmlDialogVisible"
            :close-on-click-modal="false"
            :append-to-body="true"
            :modal="false"
            :modal-append-to-body="true"
            v-drag
            width="50%">
            <div>
                <div>
                    <el-button type="primary"
                               @click="(e) => $clipboard(xml, e)">一键复制
                    </el-button>
                    <el-button
                        @click="xmlDialogVisible = false">关闭
                    </el-button>
                </div>
                <div class="block"></div>
                <x-xml-editor v-model="xml" :options="{height: 500, }" @change="handleXmlChange"></x-xml-editor>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "Bpmn",
    data() {
        return {
            formModel: {
                xml: `<?xml version="1.0" encoding="UTF-8"?>
                    <definitions xmlns="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:omgdi="http://www.omg.org/spec/DD/20100524/DI" xmlns:omgdc="http://www.omg.org/spec/DD/20100524/DC" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" id="sid-38422fae-e03e-43a3-bef4-bd33b32041b2" targetNamespace="http://bpmn.io/bpmn" exporter="bpmn-js (https://demo.bpmn.io)" exporterVersion="5.1.2">
                    <process id="Process_1" isExecutable="false">
                        <startEvent id="StartEvent_1y45yut" name="开始">
                        <outgoing>SequenceFlow_0h21x7r</outgoing>
                        </startEvent>
                        <task id="Task_1hcentk">
                        <incoming>SequenceFlow_0h21x7r</incoming>
                        </task>
                        <sequenceFlow id="SequenceFlow_0h21x7r" sourceRef="StartEvent_1y45yut" targetRef="Task_1hcentk" />
                    </process>
                    <bpmndi:BPMNDiagram id="BpmnDiagram_1">
                        <bpmndi:BPMNPlane id="BpmnPlane_1" bpmnElement="Process_1">
                        <bpmndi:BPMNShape id="StartEvent_1y45yut_di" bpmnElement="StartEvent_1y45yut">
                            <omgdc:Bounds x="152" y="102" width="36" height="36" />
                            <bpmndi:BPMNLabel>
                            <omgdc:Bounds x="160" y="145" width="22" height="14" />
                            </bpmndi:BPMNLabel>
                        </bpmndi:BPMNShape>
                        <bpmndi:BPMNShape id="Task_1hcentk_di" bpmnElement="Task_1hcentk">
                            <omgdc:Bounds x="240" y="80" width="100" height="80" />
                        </bpmndi:BPMNShape>
                        <bpmndi:BPMNEdge id="SequenceFlow_0h21x7r_di" bpmnElement="SequenceFlow_0h21x7r">
                            <omgdi:waypoint x="188" y="120" />
                            <omgdi:waypoint x="240" y="120" />
                        </bpmndi:BPMNEdge>
                        </bpmndi:BPMNPlane>
                    </bpmndi:BPMNDiagram>
                    </definitions>`
            },

            xmlDialogVisible: false,
            xml: ''
        }
    },
    methods: {

        handleXmlPreview() {
            this.$refs.designer.toXMLString().then(({xml}) => {
                this.xmlDialogVisible = true
                this.xml = xml
            })
        },
        handleCreateFromFile(file) {
            if (file.size > 10 * 1024 * 1024) { // 限制文件上传大小
                this.$message.error('单个文件大小不能超过 10M!')
                return
            }
            this.$refs.xFileButton.readAsText().then(xml => {
                this.$refs.designer.createNewDiagram(xml).catch(err => {
                    this.$message.error(err.message)
                })
            })
        },
        handleXmlChange(instance) {
            this.xml = instance.getValue()
        }
    }
}
</script>

<style scoped>
::v-deep .bpmn-icon-task.red {
    color: #cc0000 !important;
}


</style>
