import Vue from 'vue'

import './x-icon'
import './xmap'
import './x-fabric'
import './x-bpmn'
import './x-code-editor'
import './x-file-button'
import './x-label-designer'

import XDraggableDialog from './x-draggable-dialog'


Vue.use(XDraggableDialog)
