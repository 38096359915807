<template>
    <div class="full-height">

        <el-card class="box-card">
            <el-form label-width="70px" @submit.native.prevent size="small">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="操作类型">
                            <el-radio-group v-model="formModel.operationType">
                                <el-radio label="view" border>查看单据</el-radio>
                                <el-radio label="sync" border>同步单据</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="单据类型">
                            <el-radio-group v-model="formModel.billType" @change="handleBillTypeChange">
                                <el-radio v-for="config in billConfigs" :label="config.key" :key="config.key" border>
                                    {{ config.title }}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="单号">
                            <el-input v-model="formModel.billNo" placeholder="多个单号以逗号隔开（仅同步单据有效）"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="block"></div>
                <el-row>
                    <el-col :span="24">
                        <el-form-item>
                            <el-button type="primary" @click="handleRun" :disabled="btnRunDisabled">运行</el-button>
                            <el-button @click="handleClear">清空</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <div style="height: 10px"></div>
        <el-card class="box-card ">
            <el-input type="textarea" v-model="formModel.responseBody" :autosize="{ minRows: 20, maxRows: 20 }"
                      resize="none"></el-input>
        </el-card>
    </div>

</template>


<script>
import qs from 'qs';

export default {
    name: "XjkpKingdee",
    components: {},
    data() {
        return {
            content: '',
            btnRunDisabled: false,
            formModel: {
                operationType: 'view',
                billType: 'prdhbd'
            },
            wmsUrl: '/xjkp/third/kingdee',
            billConfigs: [
                {
                    key: 'prdhbd',
                    title: '生产汇报单',
                    viewUrl: '/prdhbd',
                    syncUrl: '/man/sync/prd/report',
                    example: 'SCHB00000161'
                },
                {key: 'por', title: '收料通知单', viewUrl: '/por', syncUrl: '/man/sync/receive/notice', example: 'CGSL000183'},
                {key: 'son', title: '发货通知单', viewUrl: '/son', syncUrl: '/man/sync/out/notice', example: 'FHTZD000663'},
                {key: 'to', title: '调拨申请单', viewUrl: '/to', syncUrl: '/man/sync/trans/apply', example: 'DBSQ000009'},
                {key: 'outStockApply', title: '出库申请单', viewUrl: '/outStockApply', syncUrl: '', example: 'CKSQD000004'},
                {key: 'po', title: '采购订单', viewUrl: '/po', syncUrl: '', example: 'CGSL000183'},
                {
                    key: 'pr',
                    title: '生产退料单',
                    viewUrl: 'prd/return',
                    syncUrl: '/man/sync/prd/return',
                    example: 'SCTL00000496'
                },
            ]
        }
    },
    methods: {
        handleRun() {
            let {operationType, billType, billNo} = this.formModel

            let config = this.billConfigs.filter(o => o.key == billType)[0]
            if (config == null) {
                this.$message.error('未找到相关的单据配置')
                return
            }

            let $request = null
            if (operationType == 'view') {
                $request = this.$http.get(this.wmsUrl + config.viewUrl + `/${billNo}`)
            } else {
                $request = this.$http.post(this.wmsUrl + config.syncUrl, qs.stringify({billNos: billNo}),{
                    headers: { 'content-type': 'application/x-www-form-urlencoded' }
                })
            }
            this.btnRunDisabled = true
            $request.then(res => {
                this.$set(this.formModel, 'responseBody', JSON.stringify(res))
            }).finally(() => {
                this.btnRunDisabled = false
            })

        },
        handleBillTypeChange(key) {
            let config = this.billConfigs.filter(o => o.key == key)[0]
            if (config == null) {
                return
            }
            let {billNo} = this.formModel
            this.$set(this.formModel, 'billNo', config.example)
        },
        handleClear() {
            this.formModel = {
                operationType: 'view',
                billType: 'prdhbd',
                billNo: ''
            }
            this.handleBillTypeChange('prdhbd')
        }
    },
    created() {
        this.handleClear()
    }
}
</script>

<style scoped>
.el-radio {
    margin-right: 10px;
}
</style>
