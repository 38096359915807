<template>
    <div>
        <div class="form_unit">基本信息</div>
        <label-text-properties  :canvas="canvas" :element="element"></label-text-properties>
    </div>
</template>

<script>

import LabelTextProperties from "@/extends/x-label-designer/propertiesPanel/LabelTextProperties.vue";

import mixinPanel from "../common/mixinPanel";
export default {
    name: "PropertiesPanel",
    mixins: [
        mixinPanel
    ],
    components: {
        LabelTextProperties
    },
    data() {
        return {

        }
    },
    watch: {

    },
    computed: {

    },
    created() {

    }
}
</script>

<style scoped>

</style>
