<template>
    <div>
        <el-card class="box-card">
            <el-form class="query-form" label-width="70px" @submit.native.prevent size="small" inline>

                <el-form-item label="IP地址">
                    <el-input v-model="formModel.ip" placeholder="请输入IP地址，例如1.1.1.1"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="handleQuery">查询</el-button>
                </el-form-item>

            </el-form>
        </el-card>
        <div style="height: 10px"></div>
        <el-card class="box-card">

            <vue-json-editor v-model="ipInfo" :show-btns="false" :expandedOnStart="true"
                             lang="zh" style="height: 600px;overflow: auto"></vue-json-editor>
        </el-card>
    </div>


</template>

<script>
import vueJsonEditor from 'vue-json-editor'

export default {
    name: "IpUtils",
    components: {vueJsonEditor},
    data() {
        return {
            formModel: {
                ip: ''
            },

            ipInfo: {}
        }
    },
    computed: {},
    methods: {
        handleQuery() {
            let ip = this.formModel.ip
            this.$http.get(`https://tools.yuntao.xyz/api/ipapicom?ip=${ip}&lang=zh-CN`).then(res => {
                this.ipInfo = res
            })
            // this.$http.get(`http://ip-api.com/json/${ip}?lang=zh-CN`).then(res => {
            //     this.ipInfo = res
            // })


        },
    },
    created() {
        //     https://myip.ipip.net/json
        // 获取当前IP
        this.$http.get('https://myip.ipip.net/json').then(res => {
            this.$set(this.formModel, 'ip', res.data.ip)

            this.handleQuery()
        })
    }
}
</script>

<style scoped>
.query-form .el-form-item {
    margin-bottom: 0;
}

::v-deep .jsoneditor-vue {
    height: 100%;
}
</style>
