<template>
    <codemirror ref="editor" :code="innerValue" :options="innerOption" @changes="handleChange"/>
</template>

<script>
import {codemirror} from 'vue-codemirror'


import "codemirror/lib/codemirror.css";
import "codemirror/addon/hint/show-hint.css";

/**
 * https://codemirror.net/5/index.html
 * https://codemirror.net/5/mode/
 */
export default {
    name: "x-code-editor",
    components: {
        codemirror
    },
    props: {
        value: String,
        options: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        let defaultOptions = {
            tabSize: 4,
            styleActiveLine: true,
            lineNumbers: true,
            line: true,
            styleSelectedText: true,
            mode: 'text/javascript',
            // keyMap: "sublime",
            theme: "monokai",
            matchBrackets: true,
            showCursorWhenSelecting: true,
            extraKeys: {"Ctrl": "autocomplete"},
            hintOptions: {
                completeSingle: false
            },
            width: '100%',
            height: 200,

            // 折叠
            foldGutter: true,
            lineWrapping: true,
            gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
        }
        return {
            innerValue: this.value,
            defaultOptions,
            innerOption: Object.assign(defaultOptions, this.options || {})
        }
    },

    watch: {
        value() {
            this.innerValue = this.value
        },
        options() {
            this.innerOption = Object.assign(this.defaultOptions, this.options || {})
        }
    },
    methods: {
        handleChange(val) {
            this.$emit('change', val)
        },

        loadJsAndCss() {
            let mode = this.innerOption.mode
        }
    },
    mounted() {
        let width = this.innerOption.width || 'auto'
        let height = this.innerOption.height || 'auto'

        this.editor = this.$refs.editor.codemirror;
        this.editor.setSize(width, height);

    }
}
</script>

<style scoped>

</style>
