<template>
    <div>
        <vxe-table :data="tableData" size="mini"
                   highlight-current-row
                   stripe border show-overflow
                   auto-resize
                   align="center" ref="xTable"
                   :empty-render="{name: 'EmptyData'}"
                   resizable :height="500"
        >
            <vxe-table-column type="seq" width="50" align="center"/>
            <vxe-table-column title="操作" width="80">
                <template #default="{row,$rowIndex}">
<!--                    <el-button type="text" @click="handleDeleteSingle(row,$rowIndex)">删除
                    </el-button>-->
                    <el-button type="text" @click="(e) => $clipboard(JSON.stringify(row), e)">复制
                    </el-button>
                </template>
            </vxe-table-column>

            <vxe-table-column field="requestId" title="requestId"/>

        </vxe-table>
    </div>
</template>

<script>
export default {
    name: "History",
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            tableData: this.list
        }
    },
    watch: {
        list() {
            this.tableData = this.list
        }
    },
    methods: {
        handleDeleteSingle(row, rowIndex) {
            this.tableData.splice(rowIndex, 1)
        },
    }
}
</script>

<style scoped>

</style>
