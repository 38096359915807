<template>
    <div>
        <div>
            <el-button type="primary" @click="handleAddNew">新增</el-button>
        </div>
        <div class="block"></div>
        <vxe-table :data="tableData" size="mini"
                   highlight-current-row
                   stripe border show-overflow
                   auto-resize
                   align="center" ref="xTable"
                   :empty-render="{name: 'EmptyData'}"
                   resizable :height="300"
                   :edit-config="{trigger: 'click', mode: 'row', showStatus: true}"
        >
            <vxe-table-column type="seq" width="50" align="center"/>
            <vxe-table-column title="操作" width="80">
                <template #default="{row,$rowIndex}">
                    <el-button type="text" @click="handleDeleteSingle(row,$rowIndex)">删除
                    </el-button>
                </template>
            </vxe-table-column>

            <vxe-table-column field="event" title="事件" width="120" :edit-render="{}">
                <template #default="{ row }">
                    <span>{{ formatDict(eventDictData, row.event) }}</span>
                </template>
                <template #edit="{ row }">
                    <vxe-select v-model="row.event" transfer>
                        <vxe-option v-for="item in eventDictData" :key="item.value" :value="item.value"
                                   :label="item.label"></vxe-option>
                    </vxe-select>
                </template>
            </vxe-table-column>
            <vxe-table-column field="type" title="类型" width="120" :edit-render="{}">
                <template #default="{ row }">
                    <span>{{ formatDict(typeDictData, row.type) }}</span>
                </template>
                <template #edit="{ row }">
                    <el-select v-model="row.type" transfer>
                        <el-option v-for="item in typeDictData" :key="item.value" :value="item.value"
                                    :label="item.label"></el-option>
                    </el-select>
                </template>
            </vxe-table-column>
            <vxe-table-column field="className" title="java 类名" width="200"
                              :edit-render="{name: '$input' }"/>
            <vxe-table-column field="" title="参数" width="200"/>
        </vxe-table>
    </div>
</template>

<script>


import mixinPanel from "@/extends/x-bpmn/common/mixinPanel";
import {commonParse} from '@/extends/x-bpmn/common/parseElement'

export default {
    name: "task-listener-setting",
    mixins: [mixinPanel],
    setup() {
        return {}
    },
    data() {
        return {
            formModel: {},
            tableData: [],

            /* tooltip: `create（创建）：当任务已经创建，并且所有任务参数都已经设置时触发。<br />
                              assignment（指派）：当任务已经指派给某人时触发。请注意：当流程执行到达用户任务时，在触发create事件之前，会首先触发assignment事件。<br />
                              complete（完成）：当任务已经完成，从运行时数据中删除前触发。<br />
                              delete（删除）：在任务即将被删除前触发。请注意任务由completeTask正常完成时也会触发。
                    `*/
            eventDictData: [
                {label: '创建', value: 'create'},
                {label: '指派', value: 'assignment'},
                {label: '完成', value: 'complete'},
                {label: '删除', value: 'delete'}
            ],
            typeDictData: [
                {label: '类', value: 'class'},
                {label: '表达式', value: 'expression'},
                {label: '委托表达式', value: 'delegateExpression'}
            ]
        }
    },

    watch: {
        element() {
            this.initFormModel()
        },

        'formData.candidateGroups': function (val) {
            if (this.formData.userType !== 'candidateGroups') {
                delete this.element.businessObject.$attrs[`flowable:candidateGroups`]
                return
            }
            this.updateProperties({'flowable:candidateGroups': val?.join(',')})
        },

    },
    methods: {
        initFormModel() {
            if (this.element == null) {
                return
            }
            this.formModel = commonParse(this.element)
            this.tableData = this.element.businessObject.extensionElements?.values
                .filter(item => item.$type === 'flowable:TaskListener')
                .map(item => {
                    let type
                    if ('class' in item) type = 'class'
                    if ('expression' in item) type = 'expression'
                    if ('delegateExpression' in item) type = 'delegateExpression'
                    return {
                        event: item.event,
                        type: type,
                        className: item[type],
                        params: item.fields?.map(field => {
                            let fieldType
                            if ('stringValue' in field) fieldType = 'stringValue'
                            if ('expression' in field) fieldType = 'expression'
                            return {
                                name: field.name,
                                type: fieldType,
                                value: field[fieldType]
                            }
                        }) ?? []
                    }
                }) ?? []
        },
        handleAddNew() {
            let data = {}
            this.$refs.xTable.insertAt(data, -1)
        },
        formatDict(dicts, value) {
            return dicts.find(o => o.value == value)?.[0]?.label || ''
        },
        handleDeleteSingle(row, rowIndex) {
            this.tableData.splice(rowIndex, 1)
        },
        updateElement() {
            if (this.formData.taskListener?.length) {
                let extensionElements = this.element.businessObject.get('extensionElements')
                if (!extensionElements) {
                    extensionElements = this.modeler.get('moddle').create('bpmn:ExtensionElements')
                }
                // 清除旧值
                extensionElements.values = extensionElements.values?.filter(item => item.$type !== 'flowable:TaskListener') ?? []
                this.formData.taskListener.forEach(item => {
                    const taskListener = this.modeler.get('moddle').create('flowable:TaskListener')
                    taskListener['event'] = item.event
                    taskListener[item.type] = item.className
                    if (item.params && item.params.length) {
                        item.params.forEach(field => {
                            const fieldElement = this.modeler.get('moddle').create('flowable:Field')
                            fieldElement['name'] = field.name
                            fieldElement[field.type] = field.value
                            // 注意：flowable.json 中定义的string和expression类为小写，不然会和原生的String类冲突，此处为hack
                            // const valueElement = this.modeler.get('moddle').create(`flowable:${field.type}`, { body: field.value })
                            // fieldElement[field.type] = valueElement
                            taskListener.get('fields').push(fieldElement)
                        })
                    }
                    extensionElements.get('values').push(taskListener)
                })
                this.updateProperties({extensionElements: extensionElements})
            } else {
                const extensionElements = this.element.businessObject[`extensionElements`]
                if (extensionElements) {
                    extensionElements.values = extensionElements.values?.filter(item => item.$type !== 'flowable:TaskListener') ?? []
                }
            }
        },
    },
    created() {
        this.initFormModel()

    }
}
</script>

<style scoped>

</style>
