import showConfig from './showConfig'


export default {
    props: {
        modeler: {
            type: Object,
            required: true
        },
        element: {
            type: Object,
            required: true
        },
    },
    methods: {
        updateProperties(properties) {
            const modeling = this.modeler.get('modeling')
            modeling.updateProperties(this.element, properties)
        },
        isProcessElement() {
            return (this.element?.type || '') == 'bpmn:Process'
        }
    },
    computed: {
        cpElementType() {
            const bizObj = this.element.businessObject
            return  bizObj.eventDefinitions
                ? bizObj.eventDefinitions[0].$type
                : bizObj.$type
        },
        cpShowConfig() {
            return showConfig[this.cpElementType] || {}
        }
    }
}
