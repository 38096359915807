var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleAddNew}},[_vm._v("新增")])],1),_c('div',{staticClass:"block"}),_c('vxe-table',{ref:"xTable",attrs:{"data":_vm.tableData,"size":"mini","highlight-current-row":"","stripe":"","border":"","show-overflow":"","auto-resize":"","align":"center","empty-render":{name: 'EmptyData'},"resizable":"","height":300,"edit-config":{trigger: 'click', mode: 'row', showStatus: true}}},[_c('vxe-table-column',{attrs:{"type":"seq","width":"50","align":"center"}}),_c('vxe-table-column',{attrs:{"title":"操作","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $rowIndex = ref.$rowIndex;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleDeleteSingle(row,$rowIndex)}}},[_vm._v("删除 ")])]}}])}),_c('vxe-table-column',{attrs:{"field":"event","title":"事件","width":"120","edit-render":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.formatDict(_vm.eventDictData, row.event)))])]}},{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-select',{attrs:{"transfer":""},model:{value:(row.event),callback:function ($$v) {_vm.$set(row, "event", $$v)},expression:"row.event"}},_vm._l((_vm.eventDictData),function(item){return _c('vxe-option',{key:item.value,attrs:{"value":item.value,"label":item.label}})}),1)]}}])}),_c('vxe-table-column',{attrs:{"field":"type","title":"类型","width":"120","edit-render":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.formatDict(_vm.typeDictData, row.type)))])]}},{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('el-select',{attrs:{"transfer":""},model:{value:(row.type),callback:function ($$v) {_vm.$set(row, "type", $$v)},expression:"row.type"}},_vm._l((_vm.typeDictData),function(item){return _c('el-option',{key:item.value,attrs:{"value":item.value,"label":item.label}})}),1)]}}])}),_c('vxe-table-column',{attrs:{"field":"className","title":"java 类名","width":"200","edit-render":{name: '$input' }}}),_c('vxe-table-column',{attrs:{"field":"","title":"参数","width":"200"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }