<template>
    <div class="x-file-button">
        <el-button v-bind="$props"
                   @click="handleOpenChooseFile">{{ title }}
        </el-button>
        <input ref="fileinput" type="file" :id="fileInputId" style="display: none;"
               :accept="accept" @change="handleChoosedFile"/>
    </div>
</template>

<script>

const buttonProps = {
    type: {
        type: String,
        default: 'default'
    },
    size: String,
    icon: {
        type: String,
        default: 'el-icon-upload'
    },
    nativeType: {
        type: String,
        default: 'button'
    },
    loading: Boolean,
    disabled: Boolean,
    plain: Boolean,
    autofocus: Boolean,
    round: Boolean,
    circle: Boolean
}
export default {
    name: "x-file-button",
    props: {
        ...buttonProps,

        title: {
            type: String,
            default: '上传文件'
        },
        accept: String,
        /*fileValidMethod: {
            type: Function,
            default: (file) => {
                return true
            }
        }*/
    },
    data() {
        return {
            buttonProps,
            fileInputId: `x-file-iput-${Math.ceil(Math.random() * 10000)}`,
            selectedFile: null
        }
    },
    methods: {
        handleOpenChooseFile() {
            document.querySelector(`#${this.fileInputId}`).click()
        },
        handleChoosedFile(e) {
            let file = e.target.files[0]
            this.selectedFile = file
            this.$refs.fileinput.value = null

            this.$emit('change', file)
        },
        readAsDataURL() {
            if (!this.selectedFile) {
                return Promise.reject({err: '请先选择文件'})
            }
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = (evt) => {
                    resolve(evt.target.result);
                };
                reader.onerror = (err) => {
                    reject(err)
                }
                reader.readAsDataURL(this.selectedFile);
            })
        },
        readAsText() {
            if (!this.selectedFile) {
                return Promise.reject({err: '请先选择文件'})
            }
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = (evt) => {
                    resolve(evt.target.result);
                };
                reader.onerror = (err) => {
                    reject(err)
                }
                reader.readAsText(this.selectedFile);
            })
        },
        readAsArrayBuffer() {
            if (!this.selectedFile) {
                return Promise.reject({err: '请先选择文件'})
            }
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = (evt) => {
                    resolve(evt.target.result);
                };
                reader.onerror = (err) => {
                    reject(err)
                }
                reader.readAsArrayBuffer(this.selectedFile);
            })
        },
    }
}
</script>

<style>
.el-button + .x-file-button, .x-file-button + .el-button {
    margin-left: 10px;
}
</style>
<style scoped>
.x-file-button {
    display: inline-block;
}
</style>
