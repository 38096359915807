import messages from "./message";

export default function customTranslate(template, replacements) {
    replacements = replacements || {};

    // Translate
    template = messages[template] || template;

    // Replace
    return template.replace(/{([^}]+)}/g, function (_, key) {
        var str = replacements[key];
        if (
            messages[replacements[key]] != null &&
            messages[replacements[key]] != "undefined"
        ) {
            str = messages[replacements[key]];
        }
        return str || "{" + key + "}";
    });
}
