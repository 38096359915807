<template>
    <el-form label-width="80px" label-position="right">
        <el-form-item label="流程分类" v-if="isProcessElement()">
            <el-input v-model="formModel.category" />
        </el-form-item>
        <el-form-item label="节点类型">
            <el-input disabled v-model="formModel.type"/>
        </el-form-item>
        <el-form-item label="节点标识">
            <el-input v-model="formModel.id"/>
        </el-form-item>
        <el-form-item label="节点名称">
            <el-input v-model="formModel.name"/>
        </el-form-item>
        <el-form-item label="节点描述">
            <el-input style="align-self: flex-start" :rows="2" type="textarea" v-model="formModel.documentation"/>
        </el-form-item>
    </el-form>
</template>

<script>

import mixinPanel from "@/extends/x-bpmn/common/mixinPanel";
import {commonParse} from '@/extends/x-bpmn/common/parseElement'

export default {
    name: "base-setting",
    mixins: [mixinPanel],
    props: {

    },
    data() {
        return {

            formModel: {}
        }
    },
    watch: {
        element() {
            this.initFormModel()
        },
        'formModel.category': function(val)  {
            this.updateProperties( {
                'flowable:processCategory': val
            })
        },
        'formModel.id':function (val) {
            this.updateProperties( {
                id: val
            })
        },
        'formModel.name': function(val)  {
            this.updateProperties( {
                name: val
            })
        },
        'formModel.documentation': function(val)  {
            if (!val) {
                this.updateProperties({ documentation: [] })
                return
            }
            const documentationElement = this.modeler.get('moddle').create('bpmn:Documentation', { text: val })
            this.updateProperties({ documentation: [documentationElement] })
        }
    },
    methods: {
        initFormModel() {
            if (this.element == null) {
                return
            }
            /*let {id, businessObject: {name}, type, text} = this.element
            const docs = this.element.businessObject.get("documentation")
            this.formModel = {
                id, name, type, description: docs?.[0]?.text || ''
            }*/

            this.formModel = commonParse(this.element)
        },
    },
    mounted() {
        this.initFormModel()
    }
}
</script>

<style scoped>

</style>
