import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import CodeGenerate from '@/views/CodeGenerate'
import JsonFormatter from '@/views/JsonFormatter'
import XjkpKingdee from "@/views/XjkpKingdee";
import StringEncDec from "@/views/StringEncDec";
import WebSocket from "@/views/WebSocket";
import VideoParse from "@/views/VideoParse";
import NetdiskParse from "@/views/NetdiskParse";
import BaiduMap from "@/views/BaiduMap";
import Ocr from "@/views/Ocr";
import Bpmn from "@/views/Bpmn";
import LabelDesigner from "@/views/LabelDesigner";
import ColorConversion from "@/views/ColorConversion.vue";
import UnitConversion from "@/views/UnitConversion.vue";
import ProxyDetect from "@/views/ProxyDetect.vue";
import IpUtils from "@/views/IpUtils.vue";

Vue.use(Router)

const sendToAnalytics = () => {
    if (typeof ackeeTracker === 'undefined') {
        return
    }
    ackeeTracker.detect()

    /*const elem = document.querySelector('[data-ackee-domain-id]')
    if (elem == null) return

    const server = elem.getAttribute('data-ackee-server') || ''
    const domainId = elem.getAttribute('data-ackee-domain-id')
    const options = elem.getAttribute('data-ackee-opts') || '{}'*/

    // ackeeTracker.create(server, JSON.parse(options)).record(domainId)
}
const router = new Router({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            redirect: {path: 'code-generate'},
            children: [{
                path: '/code-generate',
                name: 'CodeGenerate',
                component: CodeGenerate
            }, {
                path: '/json-formatter',
                name: 'JsonFormatter',
                component: JsonFormatter
            },
                {
                    path: '/ip-utils',
                    name: 'IpUtils',
                    component: IpUtils
                },
                {
                    path: '/xjkp-kingdee',
                    name: 'XjkpKingdee',
                    component: XjkpKingdee
                }, {
                    path: '/string-enc-dec',
                    name: 'StringEncDec',
                    component: StringEncDec
                }, {
                    path: '/websocket',
                    name: 'WebSocket',
                    component: WebSocket
                }, {
                    path: '/video-parse',
                    name: 'VideoParse',
                    component: VideoParse
                }, {
                    path: '/netdisk-parse',
                    name: 'NetdiskParse',
                    component: NetdiskParse
                }, {
                    path: '/baidu-map',
                    name: 'BaiduMap',
                    component: BaiduMap
                }, {
                    path: '/ocr',
                    name: 'Ocr',
                    component: Ocr
                }, {
                    path: '/bpmn',
                    name: 'Bpmn',
                    component: Bpmn
                }, {
                    path: '/label-designer',
                    name: 'LabelDesigner',
                    component: LabelDesigner
                }, {
                    path: '/color-conversion',
                    name: 'ColorConversion',
                    component: ColorConversion
                }, {
                    path: '/unit-conversion',
                    name: 'UnitConversion',
                    component: UnitConversion
                }, {
                    path: '/proxy-detect',
                    name: 'ProxyDetect',
                    component: ProxyDetect
                }]
        }
    ]
})
router.afterEach((to, from) => {
    sendToAnalytics(to.fullPath)
})
export default router
