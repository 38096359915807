<template>
    <div>
        <el-card class="box-card">

            <el-input
                placeholder="请输入视频链接"
                v-model="formModel.content" resize="none">

                <template slot="append">
                    <div>
                        <el-select v-model="formModel.parserUrl" placeholder="请选择解析线路" style="width: 150px;">
                            <el-option v-for="(parser,index) in parsers" :key="index"
                                       :label="parser.title" :value="parser.url"></el-option>
                        </el-select>
                        <el-button type="primary"
                                   style="margin-left: 10px;color: #FFF; background-color: #409EFF;border-color: #409EFF;"
                                   @click="handleParse">解析</el-button>
                    </div>
                </template>
            </el-input>
<!--            <div class="block"></div>
            <el-alert :closable="false">
                支持解析百度网盘，优酷，乐视，芒果，搜狐，快手，土豆，PPTV，华数TV，爱奇艺，腾讯，优酷，ACFUN，哔哩哔哩，QQ空间视频，韩国棒子云，搜狐云，M3U8，1905电影，拉面网，音悦台，美拍，秒拍，酷6，56视频，cctv央视网，糖豆网，6间房，网易公开课，虎牙，凤凰，龙珠，YY神曲，17173等网站视频
            </el-alert>-->

        </el-card>
        <div style="height: 10px"></div>
        <el-card class="box-card " :body-style="{ paddingBottom: '0px' }">
            <el-empty style="height: 65vh" v-show="loadStatus == 'unload'" description="请输入视频链接后点击解析"></el-empty>

            <div v-loading="loading"
                 element-loading-text="拼命加载中"
                 element-loading-spinner="el-icon-loading"
                 element-loading-background="rgba(0, 0, 0, 0.8)">
                <iframe :src="formModel.iframeUrl"  ref="iframe"
                        style="height: 65vh" v-show="loadStatus != 'unload'" width="100%" frameborder="0"
                        allowfullscreen="true"
                ></iframe>
            </div>
        </el-card>
    </div>
</template>

<script>

/*
* https://blockmood.github.io/react-video/
* https://www.xn--3svy77e.cf/
* */
export default {
    name: "VideoParser",
    components: {},
    data() {
        return {
            formModel: {
                parserUrl: '//okjx.cc/?url=',
                iframeUrl: null
            },
            parsers: [
                {title: '无广告线路①(高清)', url: '//okjx.cc/?url='},
                {title: '无广告线路②(高清)', url: '//jx2022.laobandq.com/jiexi20211/8090.php?url='},
                {title: '无广告线路③(一般)', url: '//www.administratorw.com/video.php?url='},
               /* {title: '无广告线路⑤', url: '//jy.we-vip.com:5433/?url='},*/
               /* {title: '无广告线路④(剧集)', url: '//z1.m1907.cn/?a=1&jx='},*/
            ],
            loadStatus: 'unload',

            loading: false,
            loadingInstance: null
        }
    },
    computed: {},
    mounted() {
        const { iframe } = this.$refs;
        // IE和非IE浏览器，监听iframe加载事件不一样，需要兼容
        const that = this;
        if (iframe.attachEvent) {
            // IE
            iframe.attachEvent('onload', () => {
                that.handleIframeLoaded();
            });
        } else {
            // 非IE
            iframe.onload = function () {
                that.handleIframeLoaded();
            };
        }
    },

    methods: {
        handleParse() {
            this.loading = false
            this.loadStatus = 'unload'

            let {content, parserUrl,iframeUrl} = this.formModel
            if (this.$strings.isBlank(content)) {
                return
            }
            if(this.$strings.isBlank(parserUrl)) {
                return;
            }
            this.$set(this.formModel, 'iframeUrl', '')

            let thisTimeUrl = parserUrl + encodeURIComponent(content)
            /*if(iframeUrl == thisTimeUrl) {
                this.$message.warning('已经加载完成了哦(^_−)☆，如果还未显示，请更换解析线路')
                return;
            }*/


            this.loadStatus = 'loading'

            this.$set(this.formModel, 'iframeUrl', thisTimeUrl)

            /*this.loadingInstance = this.$loading({
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });*/
            this.loading = true
        },
        handleIframeLoaded() {
            this.loadStatus = 'loaded'

            if(this.loadingInstance != null) {
                this.loadingInstance.close()
            }
            this.loading = false
        },
        stateChange() {
            this.loading = false;
        },
        handleClear() {
            this.$set(this.formModel, 'content', '')
            this.$set(this.formModel, 'result', '')
        }
    }
}
</script>

<style scoped>

.code-container {
    width: 100%;
    /*height: 40vh;*/
    overflow-y: auto;
}

.code-wrapper {
    display: inline-block;
    margin-right: 10px
}

.el-radio {
    margin-right: 10px;
}
</style>
