<template>
    <el-dialog :title="title" class="x-dialog-form" :visible.sync="dialogVisible"
               :show-close="showClose" :class="{'dialog-header-hidden': title == null}"
               :modal="false" label-width="80" top="0vh" fullscreen ref="dialog__wrapper">
        <div v-dialog-drag-width="$refs.dialog__wrapper"></div>
        <slot></slot>
    </el-dialog>
</template>

<script>
export default {
    name: "x-draggable-dialog",
    props: {
        title: {
            type: String,
            default: () => '详情'
        },
        visible: {
            type: Boolean,
            required: true,
            default: false
        },
        showClose: true
    },
    watch: {
        visible() {
            this.dialogVisible = this.visible
        },
        dialogVisible() {
            this.$emit('update:visible', this.dialogVisible)
        }
    },
    data() {
        return {
            dialogVisible: this.visible
        }
    }
}
</script>

<style lang="scss">

.x-dialog-form {
    position: absolute;
    top: 0px;
    left: 60%;
    right: auto;
    bottom: auto;
    width: 40%;
    height: 100%;
    border-left: 1px solid #ebeef5;
    border-top: 1px solid #ebeef5;
    border-bottom: 1px solid #ebeef5;
    margin-top: 0px;
    margin-bottom: 0px;


}
/*::v-deep .x-dialog-form.dialog-header-hidden .el-dialog__header {
    display: none;
}*/
</style>

<style scoped>
::v-deep .el-dialog__body {
    height: calc(100% - 90px);
}
</style>
