<template>
    <div>
        <canvas :id="id" :width="width" :height="height"></canvas>
    </div>
</template>

<script type="text/ecmascript-6">

// http://fabricjs.com/docs/fabric.Canvas.html

import {fabric} from "fabric";
import '../css/vue-fabric.min.css';
// import 'fabric-customise-controls';


// import '../js/customiseControls.min.js'
/*
* <script src="../../../../node_modules/fabric-customise-controls/dist/customiseControls.js"><script> */

import Utils from '../utils';

const dotCircleImg = require('../assets/dot-circle.png');
const rotateMdrImg = require('../assets/rotate-mdr.png');
export default {
    name: 'XFabric',
    props: {
        id: {
            type: String,
            required: false,
            default: 'fabricCanvas'
        },
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        allowMove: true,
        allowZoom: {
            type: Boolean,
            required: true,
            default: () => true
        },
        allowDelete: {
            type: Boolean,
            default: () => false
        },
        allowCopyAndPaste: {
            type: Boolean,
            default: () => false
        },

    },
    data() {
        return {
            canvas: null,
            currentObj: null
        };
    },
    created() {
    },
    mounted() {
        this.canvas = new fabric.Canvas(this.id, {preserveObjectStacking: true, allowTouchScrolling: true});
        let canvas = this.canvas;

        /*fabric.Canvas.prototype.customiseControls({
            tl: {
                action: 'scale'
                // cursor: '../../assets/dot-circle.png',
            },
            tr: {
                action: 'scale'
            },
            bl: {
                action: 'scale',
                cursor: 'pointer'
            },
            br: {
                action: 'scale',
                cursor: 'pointer'
            },
            mb: {
                action: 'scale',
                cursor: 'pointer'
            },
            // mr: {
            //     // action: function(e, target) {
            //     //     target.set({
            //     //         left: 200,
            //     //     });
            //     //     canvas.renderAll();
            //     // },
            //     action: 'scale',
            //     cursor: 'pointer',
            // },
            mt: {
                // action: {
                //   rotateByDegrees: 30
                // },
                action: 'scale',
                cursor: 'pointer'
            },
            // only is hasRotatingPoint is not set to false
            mtr: {
                action: 'rotate'
                // cursor: '../../assets/cow.png',
            }
        });*/
        // this.setCornerIcons({});
        // canvas.add(new fabric.Circle({ radius: 30, fill: '#f55', top: 100, left: 100 }));
        canvas.backgroundColor = '#ffffff';
        // canvas.renderAll();
        // this.canvas.push(canvas);
        let that = this;
        this.canvas.controlsAboveOverlay = false;
        this.canvas.skipOffscreen = true;
        // this.drawControls();
        this.canvas.on('selection:created', function (options) {
            that.$emit('selection:created', options);
        });
        /*this.canvas.on('mouse:wheel', function (options) {
            that.$emit('mouse:wheel', options);
        });
        this.canvas.on('mouse:down', function (options) {
            that.$emit('mouse:down', options);
        });
        this.canvas.on('mouse:up', function (options) {
            that.$emit('mouse:up', options);
        });
        this.canvas.on('mouse:move', function (options) {
            that.$emit('mouse:move', options);
        });*/

        this._addMouseEventListen()
        this._addKeyboardEvent()

        this.canvas.on('object:added', function (options) {
            that.$emit('object:added', options);
        });
        this.canvas.on('object:removed', function (options) {
            that.$emit('object:removed', options);
        });
        this.canvas.on('object:moving', function (options) {
            // 判断是否碰撞到其他对象
            canvas.forEachObject(function(obj) {
                if (obj === options.target) return; // 不需要和自己比较
                if (options.target.intersectsWithObject(obj) || options.target.isContainedWithinObject(obj)) {
                    options.target.bringToFront(); // 碰撞到的时候置于顶层
                }
            });

            options.e.preventDefault()
            that.$emit('object:moving', options);
        });
        this.canvas.on('object:modified', function (options) {
            that.$emit('object:modified', options);
        });
        this.canvas.on('object:rotating', function (options) {
            that.$emit('object:rotating', options);
        });
        this.canvas.on('object:scaling', function (options) {
            that.$emit('object:scaling', options);
        });

        this.canvas.on('selection:updated', function (options) {
            that.$emit('selection:updated', options);
        });
        this.canvas.on('selection:cleared', function (options) {
            that.$emit('selection:cleared', options);
        });
        this.canvas.on('before:selection:cleared', function (options) {
            that.$emit('before:selection:cleared', options);
        });

        this.canvas.on('after:render', function (options) {
            that.$emit('after:render', options);
        });
    },
    methods: {
        _addMouseEventListen() {
            let that = this
            let canvas = this.canvas
            let viewportLeft = 0,
                viewportTop = 0,
                mouseLeft,
                mouseTop,
                _drawSelection = canvas._drawSelection,
                isDown = false;

            this.canvas.on('mouse:wheel', (options) => {
                let e = options.e;
                let newZoom = canvas.getZoom() - e.deltaY / 300;
                if (newZoom < 0.1) {
                    return
                }
                if (that.allowZoom) {
                    canvas.zoomToPoint({x: e.offsetX, y: e.offsetY}, newZoom);
                }
                e.preventDefault();
            });
            this.canvas.on('mouse:down', function (options) {
                that.$emit('mouse:down', options)
                if (options.target != null) {
                    // 说明鼠标在某个组件中按下，不要触发canvas，否则会导致异常
                    return
                }
                isDown = true;
                viewportLeft = canvas.viewportTransform[4];
                viewportTop = canvas.viewportTransform[5];

                mouseLeft = options.e.x;
                mouseTop = options.e.y;
                _drawSelection = canvas._drawSelection;
                canvas._drawSelection = function () {
                };
            });

            this.canvas.on('mouse:move', function (options) {
                if (!this.allowMove) {
                    return
                }
                if (isDown) {
                    let currentMouseLeft = options.e.x;
                    let currentMouseTop = options.e.y;

                    let deltaLeft = currentMouseLeft - mouseLeft,
                        deltaTop = currentMouseTop - mouseTop;

                    canvas.viewportTransform[4] = viewportLeft + deltaLeft;
                    canvas.viewportTransform[5] = viewportTop + deltaTop;

                    canvas.renderAll();
                }
            });

            this.canvas.on('mouse:up', function (options) {
                canvas._drawSelection = _drawSelection;
                isDown = false;
            });

            this.canvas.on('mouse:dblclick', function (options) {
                that.$emit('mouse:dblclick', options);
            });
            this.canvas.on('mouse:over', function (options) {
                that.$emit('mouse:over', options);
            });
            this.canvas.on('mouse:out', function (options) {
                that.$emit('mouse:out', options);
            });

        },

        _addKeyboardEvent() {
            let canvas = this.canvas


            const handleDelete = (activeObject) => {
                if (!this.allowDelete) {
                    return
                }
                if (activeObject.type === 'activeSelection') {
                    // active selection needs a reference to the canvas.
                    activeObject.forEachObject(function (obj) {
                        canvas.remove(obj)
                    });
                } else {
                    canvas.remove(activeObject)
                }
                canvas.discardActiveObject()
            }

            document.onkeydown = (e) => {
                let activeObject = canvas.getActiveObject()
                /*if((!activeObject || !activeObject.isEditing) && this.hasFocus()) {
                    /!* ctrl+a 全选*!/
                    if (e.ctrlKey && e.key === 'a') {
                        e.preventDefault();
                        this.selectAll()
                    }
                    return;
                }*/

                if (!activeObject || activeObject.isEditing) {
                    return
                }

                let {key} = e
                switch (key) {
                    case 'ArrowUp':  /* Up arrow */
                        activeObject.top -= 5;
                        break;
                    case 'ArrowDown':  /* Down arrow  */
                        activeObject.top += 5;
                        break;
                    case 'ArrowLeft':  /* Left arrow  */
                        activeObject.left -= 5;
                        break;
                    case 'ArrowRight':  /* Right arrow  */
                        activeObject.left += 5;
                        break;
                    case 'Delete':  /* delete */
                        handleDelete(activeObject)
                        break;
                }
                canvas.requestRenderAll();
            }

            if (this.allowCopyAndPaste) {

                this._addCopyAndPasteEvent()
            }

        },
        /**
         * 还不完善，先这样吧
         * @private
         */
        _addCopyAndPasteEvent() {
            let canvas = this.canvas
            let _clipboard = null

            // 处理复制操作
            function handleCopy() {
                // 获取当前选中的组件
                const activeObject = canvas.getActiveObject();
                if (!activeObject) {
                    return;
                }
                if (activeObject.isEditing) {
                    return;
                }

                activeObject.clone(function (cloned) {
                    _clipboard = cloned;
                });
            }

            function handleCut() {
                // 获取当前选中的组件
                const activeObject = canvas.getActiveObject();
                if (!activeObject) {
                    return;
                }
                if (activeObject.isEditing) {
                    return;
                }

                activeObject.clone(function (cloned) {
                    _clipboard = cloned;
                    canvas.remove(activeObject)
                });
            }

            // 监听复制快捷键
            document.addEventListener('copy', handleCopy);

            // 处理粘贴操作
            function handlePaste(event) {

                // 从剪贴板中获取 JSON 对象
                const json = event.clipboardData.getData('text/plain');
                if (!json) {
                    return;
                }
                if (canvas.isDrawingMode || !_clipboard) {
                    // 如果 canvas 正在绘制，不允许粘贴操作
                    return;
                }

                _clipboard.clone((clonedObj) => {
                    canvas.discardActiveObject();
                    clonedObj.set({
                        left: clonedObj.left + 10,
                        top: clonedObj.top + 10,
                        evented: true,
                        id: `${clonedObj.type}-${new Date().getTime()}`
                    });
                    if (clonedObj.type === 'activeSelection') {
                        // active selection needs a reference to the canvas.
                        clonedObj.canvas = canvas;
                        clonedObj.forEachObject(function (obj) {
                            canvas.add(obj);
                        });
                        // this should solve the unselectability
                        clonedObj.setCoords();
                    } else {
                        canvas.add(clonedObj);
                    }
                    _clipboard.top += 10;
                    _clipboard.left += 10;
                    canvas.setActiveObject(clonedObj);
                    canvas.requestRenderAll();
                });

                event.preventDefault();
            }

            // 监听粘贴快捷键
            document.addEventListener('paste', handlePaste);

            // 注意：以上代码仅演示了基本的复制和粘贴操作，并未考虑多选、粘贴到指定位置等情况。在实际使用中，可能需要根据具体需求进行相应的修改和优化。
        },
        setCornerIcons({
                           size = 20,
                           borderColor = '#e4e4e4',
                           cornerBackgroundColor = '#ffffff',
                           cornerShape = 'rect',
                           tl = dotCircleImg,
                           tr = dotCircleImg,
                           bl = dotCircleImg,
                           br = dotCircleImg,
                           ml = dotCircleImg,
                           mr = dotCircleImg,
                           mtr = rotateMdrImg
                       }) {
            // basic settings
            let that = this;
            fabric.Object.prototype.customiseCornerIcons(
                {
                    settings: {
                        borderColor: borderColor,
                        cornerSize: size,
                        cornerShape: cornerShape,  // 'rect', 'circle'
                        cornerBackgroundColor: cornerBackgroundColor
                    },
                    tl: {
                        icon: tl
                    },
                    tr: {
                        icon: tr
                    },
                    bl: {
                        icon: bl
                    },
                    br: {
                        icon: br
                    },
                    ml: {
                        icon: ml
                    },
                    mr: {
                        icon: mr
                    },
                    // only is hasRotatingPoint is not set to false
                    mtr: {
                        icon: mtr
                    }
                },
                function () {
                    that.canvas.renderAll();
                }
            );
        },
        drawDottedline(options) {
            options = Object.assign({
                x: 0,
                y: 0,
                x1: 10,
                y1: 10,
                color: '#B2B2B2',
                drawWidth: 2,
                offset: 6,
                empty: 3
            }, options);
            let canvasObject = new fabric.Line([options.x, options.y, options.x1, options.y1], {
                strokeDashArray: [options.offset, options.empty],
                stroke: options.color,
                strokeWidth: options.drawWidth,
                ...options,
            });
            this.canvas.add(canvasObject);
            this.canvas.renderAll();
        },
        drawArrowLine(options) {
            options = Object.assign({
                x: 0,
                y: 0,
                x1: 0,
                y1: 0,
                color: '#B2B2B2',
                drawWidth: 2,
                fillColor: 'rgba(255,255,255,0)',
                theta: 35,
                headlen: 35
            }, options);
            let canvasObject = new fabric.Path(this.drawArrowBase(options.x, options.y, options.x1, options.y1, options.theta, options.headlen), {
                stroke: options.color,
                fill: options.fillColor,
                strokeWidth: options.drawWidth,
                ...options,
            });
            this.canvas.add(canvasObject);
            this.canvas.renderAll();
        },
        drawArrowBase(fromX, fromY, toX, toY, theta, headlen) {
            theta = typeof theta !== 'undefined' ? theta : 30;
            headlen = typeof theta !== 'undefined' ? headlen : 10;
            // 计算各角度和对应的P2,P3坐标
            let angle = (Math.atan2(fromY - toY, fromX - toX) * 180) / Math.PI,
                angle1 = ((angle + theta) * Math.PI) / 180,
                angle2 = ((angle - theta) * Math.PI) / 180,
                topX = headlen * Math.cos(angle1),
                topY = headlen * Math.sin(angle1),
                botX = headlen * Math.cos(angle2),
                botY = headlen * Math.sin(angle2);
            let arrowX = fromX - topX,
                arrowY = fromY - topY;
            let path = ' M ' + fromX + ' ' + fromY;
            path += ' L ' + toX + ' ' + toY;
            arrowX = toX + topX;
            arrowY = toY + topY;
            path += ' M ' + arrowX + ' ' + arrowY;
            path += ' L ' + toX + ' ' + toY;
            arrowX = toX + botX;
            arrowY = toY + botY;
            path += ' L ' + arrowX + ' ' + arrowY;
            return path;
        },
        freeDrawConfig(options) {
            options = Object.assign({color: '#b2b2b2', drawWidth: 2}, options);
            this.canvas.isDrawingMode = options.isDrawingMode;
            this.canvas.freeDrawingBrush.color = options.color; // 设置自由绘颜色
            this.canvas.freeDrawingBrush.width = options.drawWidth;
            this.canvas.renderAll();
        },
        eraseDrawConfig(options) {
            options = Object.assign({color: 'white', drawWidth: 2}, options);
            this.canvas.freeDrawingBrush.color = options.color; // 设置自由绘颜色
            this.canvas.freeDrawingBrush.width = options.drawWidth;
            this.canvas.renderAll();
        },
        removeCurrentObj() {
            let obj = this.canvas.getActiveObject();
            // console.log(obj);
            this.canvas.remove(obj);
            this.canvas.renderAll();
        },
        getEditObj() {
            let obj = this.canvas.getActiveObject();
            this.removeCurrentObj();
            return obj;
        },
        setEditObj(obj) {
            this.canvas.add(obj);
            this.canvas.renderAll();
        },
        setRotate(deg = 36) {
            let obj = this.canvas.getActiveObject();
            let angle = obj.angle;
            obj.rotate(angle + deg);
            this.canvas.renderAll();
        },
        discardActive() {
            this.canvas.discardActiveObject();
            // this.canvas.discardActiveGroup();
            this.canvas.renderAll();
        },
        deactivateAll() {
            // this.canvas.deactivateAll().renderAll();
        },
        deactivateOne(obj) {
            let activeGroup = this.canvas.getActiveGroup();
            activeGroup.removeWithUpdate(obj);
            this.canvas.renderAll();
        },
        setSelection(flag) {
            this.canvas.selection = flag;
            this.canvas.renderAll();
        },
        moveTo() {
            let obj = this.canvas.getActiveObject();
            console.log(this.canvas.sendBackwards);
            this.canvas.sendBackwards(obj, true);
            this.canvas.discardActiveObject();
            // this.canvas.discardActiveGroup();
        },
        createRect(options) {
            options = Object.assign({
                width: 0,
                height: 0,
                fillColor: 'rgba(255, 255, 255, 0)',
                left: 50,
                top: 50
            }, options);
            let rect = new fabric.Rect({
                fill: options.fillColor, // 填充的颜色
                ...options,
            });
            this.canvas.add(rect);
            this.canvas.renderAll();
        },
        createCircle(options) {
            options = Object.assign({
                left: 0,
                top: 0,
                radius: 30,
                fillColor: 'rgba(255, 255, 255, 0)',
                color: '#B2B2B2',
                drawWidth: 2
            }, options);
            let defaultOption = {
                fill: options.fillColor,
                strokeWidth: options.drawWidth,
                stroke: options.color,
                ...options,
            };
            let Circle = new fabric.Circle(defaultOption);
            this.canvas.add(Circle);
            this.canvas.renderAll();
        },
        createTriangle(options) {
            options = Object.assign({
                x: 0,
                y: 0,
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 0,
                left: 100,
                top: 100,
                color: '#B2B2B2',
                drawWidth: 2,
                fillColor: 'rgba(255, 255, 255, 0)',
                id: 'triangle'
            }, options);
            let path = 'M ' + options.x + ' ' + options.y + ' L ' + options.x1 + ' ' + options.y1 + ' L ' + options.x2 + ' ' + options.y2 + ' z';
            let canvasObject = new fabric.Path(path, {
                stroke: options.color,
                strokeWidth: options.drawWidth,
                fill: options.fillColor,
                ...options,
            });
            this.canvas.add(canvasObject);
            this.canvas.renderAll();
        },
        createEqualTriangle(options) {
            options = Object.assign({
                left: 100,
                top: 100,
                width: 50,
                height: 80,
                fillColor: 'rgba(255, 255, 255, 0)',
                color: '#B2B2B2',
                drawWidth: 2
            }, options);
            // console.log(defaultOption);
            let triangle = new fabric.Triangle({
                fill: options.fillColor,
                strokeWidth: options.drawWidth,
                stroke: options.color,
                ...options,
            });
            this.setContronVisibility(triangle);
            this.canvas.add(triangle);
            this.canvas.renderAll();
        },
        createLine(options) {
            options = Object.assign({
                x: 0,
                y: 0,
                x1: 10,
                y1: 10,
                fillColor: 'rgba(255, 255, 255, 0)',
                strokeColor: '#B0B0B0'
            }, options);
            let line = new fabric.Line([options.x, options.y, options.x1, options.y1], {
                fill: options.fillColor,
                stroke: options.strokeColor,
                ...options,
            });
            this.canvas.add(line);
            this.canvas.renderAll();
        },
        createEllipse(options) {
            options = Object.assign({
                rx: 100,
                ry: 200,
                fillColor: 'rgba(255, 255, 255, 0)',
                angle: 90,
                strokeColor: '#B0B0B0',
                strokeWidth: 3,
                left: 50,
                top: 50
            }, options);
            let ellipse = new fabric.Ellipse({
                fill: options.fillColor,
                stroke: options.strokeColor,
                ...options,
            });
            this.canvas.add(ellipse);
            this.canvas.renderAll();
        },
        createText(text, options) {
            options = Object.assign({left: 100, top: 100}, options);
            let canvasObj = new fabric.Text(text, {...options});
            this.canvas.add(canvasObj);
            this.canvas.renderAll();
        },
        createItext(text, options) {
            options = Object.assign({left: 0, top: 0, fill: '#000'}, options);
            let IText = new fabric.IText(text, options);
            this.canvas.add(IText);
            this.canvas.renderAll();
        },
        createTextbox(text, options) {
            // _fontSizeMult: 5,
            options.fillColor = options.fillColor ? options.fillColor : options.fill;
            options = Object.assign({
                fontSize: 14,
                fillColor: '#000000',
                registeObjectEvent: false,
                width: 50,
                left: 100,
                top: 100
            }, options);
            let canvasObj = new fabric.Textbox(text, {
                fill: options.fillColor,
                ...options,
            });
            // let arr = canvasObj._splitTextIntoLines(text);
            // console.log(arr);
            this.canvas.add(canvasObj);
            if (options.registeObjectEvent) {
                Utils.registeObjectEvent(this, canvasObj);
            }
            this.canvas.renderAll();
        },
        createImageByImg(img, options) {
            options = options || {}
            let canvas = this.canvas;
            let that = this;
            // let maxWidth = that.width;
            let width = 0;
            let height = 0;
            width = img.width;
            height = img.height;
            // if (img.width > img.height) {
            //   if (img.width > maxWidth) {
            //     width = maxWidth;
            //     height = (img.height / img.width) * width;
            //   } else {
            //     width = img.width;
            //     height = img.height;
            //   }
            // } else {
            //   if (img.height > maxWidth) {
            //     height = maxWidth;
            //     width = (img.width / img.height) * height;
            //   } else {
            //     width = img.width;
            //     height = img.height;
            //   }
            // }
            if (options && options.width) {
                width = options.width;
            }
            if (options && options.height) {
                height = options.height;
            }
            let leftP = that.width / 2;
            let topP = that.height / 2;
            if ((options && options.left) || (options && options.left == 0)) {
                leftP = options.left + width / 2;
            }
            if ((options && options.top) || (options && options.top == 0)) {
                topP = options.top + height / 2;
            }
            let imgOptions = Object.assign(options, {
                id: (options && options.id) ? options.id : 'image',
                left: leftP,
                top: topP,
                scaleX: width / img.width,
                scaleY: height / img.height,
                originX: 'center',
                originY: 'center',
                cornerStrokeColor: 'blue'
            })
            delete imgOptions.width;
            delete imgOptions.height;
            let canvasImage = new fabric.Image(img, imgOptions)
            canvasImage.hasControls = true;
            canvasImage.hasBorders = true;
            canvas.add(canvasImage); // 把图片添加到画布上
            if (options && options.registeObjectEvent) {
                Utils.registeObjectEvent(that, canvasImage);
            }
            canvas.renderAll.bind(canvas);
        },
        createImage(url, options) {
            options = options || {}
            let canvas = this.canvas;
            let that = this;
            fabric.Image.fromURL(url, function (img) {
                // 添加过滤器
                // img.filters.push(new fabric.Image.filters.Grayscale());
                // 应用过滤器并重新渲染画布执行
                // img.applyFilters(canvas.renderAll.bind(canvas));
                // console.log(img);
                let maxWidth = that.width / 2;
                let width = 0;
                let height = 0;
                width = img.width;
                height = img.height;
                // if (img.width > img.height) {
                //   if (img.width > maxWidth) {
                //     width = maxWidth;
                //     height = (img.height / img.width) * width;
                //   } else {
                //     width = img.width;
                //     height = img.height;
                //   }
                // } else {
                //   if (img.height > maxWidth) {
                //     height = maxWidth;
                //     width = (img.width / img.height) * height;
                //   } else {
                //     width = img.width;
                //     height = img.height;
                //   }
                // }
                if (options && options.width) {
                    width = options.width;
                }
                if (options && options.height) {
                    height = options.height;
                }
                let leftP = that.width / 2;
                let topP = that.height / 2;
                if ((options && options.left) || (options && options.left == 0)) {
                    leftP = options.left + width / 2;
                }
                if ((options && options.top) || (options && options.top == 0)) {
                    topP = options.top + height / 2;
                }
                // console.log(options);
                let imgOptions = Object.assign(options, {
                    // ...options,
                    id: (options && options.id) ? options.id : 'image',
                    left: leftP,
                    top: topP,
                    scaleX: width / img.width,
                    scaleY: height / img.height,
                    originX: 'center',
                    originY: 'center',
                    cornerStrokeColor: 'blue'
                })
                delete imgOptions.width;
                delete imgOptions.height;
                console.log("imgOptions", imgOptions);
                img.set(imgOptions);
                let oldOriginX = img.get('originX');
                let oldOriginY = img.get('originY');
                let center = img.getCenterPoint();
                img.hasControls = true;
                img.hasBorders = true;
                // img.customiseCornerIcons(
                //   {
                //     settings: {
                //       borderColor: '#b4b4b4',
                //       cornerSize: 20,
                //       cornerBackgroundColor: '#FF0000',
                //       cornerShape: 'circle',
                //       cornerPadding: 0
                //     }
                //     tl: {
                //       icon: dotCircleImg
                //     },
                //     tr: {
                //       icon: dotCircleImg
                //     },
                //     bl: {
                //       icon: dotCircleImg
                //     },
                //     br: {
                //       icon: dotCircleImg
                //     },
                //     mb: {
                //       icon: dotCircleImg
                //     },
                //     mt: {
                //       icon: dotCircleImg
                //     },
                //     mr: {
                //       icon: dotCircleImg
                //     },
                //     mtr: {
                //       icon: dotCircleImg
                //     }
                //   },
                //   function () {
                //     canvas.renderAll();
                //   }
                // );
                // img.setControlsVisibility({
                //   bl: true,
                //   br: true,
                //   mb: false,
                //   ml: true,
                //   mr: true,
                //   mt: false,
                //   mtr: true,
                //   tl: true,
                //   tr: true
                // });
                canvas.add(img); // 把图片添加到画布上
                if (options && options.registeObjectEvent) {
                    Utils.registeObjectEvent(that, img);
                }
                canvas.renderAll.bind(canvas);
            });
        },
        toJson(propertiesToInclude = []) {
            let json = this.canvas.toJSON(propertiesToInclude);

            json.width = this.canvas.getWidth()
            json.height = this.canvas.getHeight()
            return json;
        },
        toDataUrl() {
            let canvas = this.canvas;
            let dataURL = canvas.toDataURL({
                format: 'jpeg',
                quality: 1
            });
            return dataURL;
        },
        loadFromJSON(json, cb) {
            let canvas = this.canvas;
            let {width, height} = json
            if (width) {
                this.canvas.setWidth(width)
            }
            if (height) {
                this.canvas.setWidth(height)
            }
            canvas.loadFromJSON(json, canvas.renderAll.bind(canvas), function (
                o,
                object
            ) {
                // `o` = json object
                // `object` = fabric.Object instance
                // ... do some stuff ...
                cb && cb(o);
                object.setControlsVisibility({
                    bl: true,
                    br: true,
                    mb: false,
                    ml: true,
                    mr: true,
                    mt: false,
                    mtr: true,
                    tl: true,
                    tr: true
                });

            });
        },
        clear() {
            this.canvas.clear();
        },
        getObjects() {
            return this.canvas.getObjects();
        },
        renderAll() {
            this.canvas.renderAll(this.canvas);
        },
        renderTop() {
            this.canvas.renderTop();
        },
        setBackgroundColor(color) {
            let canvas = this.canvas;
            this.canvas.setBackgroundColor(color, canvas.renderAll.bind(canvas));
        },
        setBackgroundImage(options) {
            let canvas = this.canvas;
            let opt = {
                opacity: 1,
                left: 0,
                top: 0,
                angle: 0,
                crossOrigin: null,
                originX: 'left',
                originY: 'top',
                scaleX: 1,
                scaleY: 1
            };
            // console.log(options);
            if (Object.prototype.toString.call(options) == "[object String]") {
                console.log("字符串兼容");
                opt.imgUrl = options;
            } else {
                opt = Object.assign(opt, options)
            }
            if (opt.image) {
                canvas.setBackgroundImage(opt.image, canvas.renderAll.bind(canvas), {
                    opacity: opt.opacity,
                    angle: opt.angle,
                    left: opt.left,
                    top: opt.top,
                    originX: 'left',
                    originY: 'top',
                    crossOrigin: opt.crossOrigin,
                    ...opt
                });
            } else {
                fabric.Image.fromURL(opt.imgUrl, function (img) {
                    img.set({
                        width: opt.width ? opt.width : canvas.width,
                        height: opt.height ? opt.height : canvas.height,
                        originX: 'left',
                        originY: 'top',
                        scaleX: opt.scaleX,
                        scaleY: opt.scaleY,
                    });
                    canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {...opt});
                });
            }
        },
        clearBackgroundImage() {
            this.canvas.setBackgroundImage(null, this.canvas.renderAll.bind(this.canvas));
        },
        toSvg() {
            return this.canvas.toSVG();
        },
        drawControls() {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            ctx.setLineDash([]);
            ctx.beginPath();
            ctx.ellipse(100, 100, 50, 75, (45 * Math.PI) / 180, 0, 2 * Math.PI); // 倾斜45°角
            ctx.stroke();
            ctx.setLineDash([5]);
            ctx.moveTo(0, 200);
            ctx.lineTo(200, 0);
            ctx.stroke();
            this.canvas.drawControls(ctx);
            // this.canvas.controlsAboveOverlay=true;
        },
        setContronVisibility(obj) {
            obj.setControlsVisibility({
                bl: true,
                br: true,
                mb: false,
                ml: true,
                mr: true,
                mt: false,
                mtr: true,
                tl: true,
                tr: true
            });
        },
        // 设置mirror
        toggleMirror(options) {
            options = options || {};
            options = Object.assign({flip: 'X'}, options);
            let img = this.canvas.getActiveObject();
            // if (img && img.type == 'image') {
            if (options.flip === 'X') {
                img.toggle('flipX');
            } else {
                img.toggle('flipY');
            }
            this.renderAll();
            // }
        },
        // 设置层级
        toUpLayer() {
            let obj = this.canvas.getActiveObject();
            if (!obj) {
                return;
            }
            obj.bringForward(true);
            this.renderTop();
            // this.canvas.setActiveObject(obj);
        },
        toDownLayer() {
            let obj = this.canvas.getActiveObject();
            if (!obj) {
                return;
            }
            obj.sendBackwards();
            this.renderTop();
            // this.canvas.setActiveObject(obj);
        },
        toLastLayer() {
            let obj = this.canvas.getActiveObject();
            if (!obj) {
                return;
            }
            obj.sendToBack();
            this.renderTop();
        },
        toTopLayer() {
            let obj = this.canvas.getActiveObject();
            if (!obj) {
                return;
            }
            obj.bringToFront();
            this.renderTop();
        },
        drawByPath(pathArray, options) {
            options = Object.assign({
                fillColor: 'rgba(255, 255, 255, 0)',
                left: 150,
                top: 150,
                strokeColor: '#B0B0B0',
                strokeWidth: 3
            }, options);
            let pathStr = 'M ';
            for (let item of pathArray) {
                pathStr = pathStr + item[0] + ' ' + item[1] + ' ';
            }
            pathStr = pathStr + 'z';
            console.log(pathStr);
            let path = new fabric.Path(pathStr);
            path.set({
                stroke: options.strokeColor,
                fill: options.fillColor,
                ...options,
            });
            this.canvas.add(path);
        },

        scale(value) {
            let canvas = this.canvas
            let scaleIt = (prop) => {
                return parseFloat(prop, 10) * value;
            };

            let currentCanvasHeight = canvas.getHeight(),
                currentCanvasWidth = canvas.getWidth(),
                scaledCanvasHeight = scaleIt(currentCanvasHeight),
                scaledCanvasWidth = scaleIt(currentCanvasWidth);

            canvas.forEachObject(function (obj) {
                let currentObjTop = obj.get('top'),
                    currentObjLeft = obj.get('left'),
                    currentObjScaleX = obj.get('scaleX'),
                    currentObjScaleY = obj.get('scaleY'),
                    scaledObjTop = scaleIt(currentObjTop),
                    scaledObjLeft = scaleIt(currentObjLeft),
                    scaledObjScaleX = scaleIt(currentObjScaleX),
                    scaledObjScaleY = scaleIt(currentObjScaleY);
                console.log(scaledObjScaleX, scaledObjScaleY)
                obj.set({
                    top: scaledObjTop,
                    left: scaledObjLeft,
                    scaleX: scaledObjScaleX,
                    scaleY: scaledObjScaleY
                });
                obj.setCoords();

            });

            canvas.scaleValue = value;
            canvas.renderAll();
        },

        addDeleteControl() {
            console.log('...........')
            let deleteObject = (eventData, transform) => {
                let target = transform.target;
                let canvas = target.canvas;
                canvas.remove(target);
                canvas.requestRenderAll();
            }

            let deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
            let img = document.createElement('img');
            img.src = deleteIcon;
            let renderIcon = (ctx, left, top, styleOverride, fabricObject) => {
                let size = this.cornerSize;
                ctx.save();
                ctx.translate(left, top);
                ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                ctx.drawImage(img, -size / 2, -size / 2, size, size);
                ctx.restore();
            }
            fabric.Object.prototype.controls.deleteControl = new fabric.Control({
                x: 0.5,
                y: -0.5,
                offsetY: 16,
                cursorStyle: 'pointer',
                mouseUpHandler: deleteObject,
                render: renderIcon,
                cornerSize: 24
            });
        },
        group() {
            if (!this.canvas.getActiveObject()) {
                return;
            }
            if (this.canvas.getActiveObject().type !== 'activeSelection') {
                return;
            }
            this.canvas.getActiveObject().toGroup();
            this.canvas.requestRenderAll();
        },
        ungroup() {
            if (!this.canvas.getActiveObject()) {
                return;
            }
            if (this.canvas.getActiveObject().type !== 'group') {
                return;
            }
            this.canvas.getActiveObject().toActiveSelection();
            this.canvas.requestRenderAll();
        },

        selectAll() {

            this.canvas.discardActiveObject();
            this.canvas.setActiveObject(new fabric.ActiveSelection(this.canvas.getObjects(), {
                canvas: this.canvas
            }));
            this.canvas.requestRenderAll();
        }
    }
};
</script>

<style lang="scss" scoped></style>
