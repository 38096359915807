<template>
    <div>
        <div style="height: 10px"></div>
        <el-card class="box-card" :body-style="{ paddingBottom: '10px' }">

            <!--            <el-button @click="$refs.designer.createNewDiagram()" icon="el-icon-notebook-1">新建</el-button>

                        <el-divider direction="vertical"></el-divider>

                        <el-button @click="$refs.designer.toSVG()" icon="el-icon-download">下载为SVG</el-button>
                        <el-button @click="$refs.designer.toXML()" icon="el-icon-download">下载为XML</el-button>
                        <el-divider direction="vertical"></el-divider>

                        <el-button @click="" icon="el-icon-view">XML预览</el-button>
                        <el-button @click="$refs.designer.toggleMiniMap()" icon="el-icon-view">显隐小地图</el-button>
                        &lt;!&ndash;            <el-button @click="handleCreateFromFile" icon="el-icon-view">打开文件</el-button>&ndash;&gt;

                        <el-button @click="$message.success(JSON.stringify($refs.designer.getProcess()))" icon="el-icon-view">流程信息</el-button>-->

            <el-form inline class="page-form">
                <el-form-item label="纸张宽度">
                    <el-input v-model="formModel.widthMm" placeholder="">
                        <template #append>mm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="纸张高度">
                    <el-input v-model="formModel.heightMm" placeholder="">
                        <template #append>mm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="DPI">
                    <el-input v-model="formModel.dpi" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="纸张宽度">
                    <el-input v-model="formModel.widthPx" disabled placeholder="">
                        <template #append>px</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="纸张高度">
                    <el-input v-model="formModel.heightPx" disabled placeholder="">
                        <template #append>px</template>
                    </el-input>
                </el-form-item>
                <el-button @click="handleCreateNew" icon="el-icon-notebook-1">新建</el-button>
                <el-button @click="handleChangePageSize" icon="el-icon-notebook-1">改变大小</el-button>
            </el-form>

        </el-card>
        <div class="block"></div>

        <el-card class="box-card" :body-style="{ paddingBottom: '10px' }">
            <x-label-designer ref="designer" :height="formModel.heightPx" :width="formModel.widthPx"></x-label-designer>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "LabelDesigner",
    data() {
        return {
            formModel: {
                widthMm: 60,
                heightMm: 60,
                widthPx: 0,
                heightPx: 0,
                dpi: 200,
            }
        }
    },
    methods: {
        calcPagePx(mm) {
            let { dpi} = this.formModel
            if (mm <= 0 || dpi <= 0) {
                return
            }

            // dpi = 每一英寸长度中，取样、可显示或输出点的数目
            // 1 英寸 = 25.4 毫米
            // 以200dpi打印机为例，宽度60mm，换算成英寸为 60 / 25.4 = 2.36，纸张像素点 = 2.36 * 200 = 472
            return Math.round(mm / 25.4 * dpi)
        },
        calcAndSetPx() {
            let { widthMm,heightMm} = this.formModel
            let width = this.calcPagePx(widthMm)
            let height = this.calcPagePx(heightMm)

            this.$set(this.formModel, 'widthPx', width)
            this.$set(this.formModel, 'heightPx', height)
        },

        handleCreateNew() {
            this.$refs.designer.clear()

            this.handleChangePageSize()
        },
        handleChangePageSize() {
            this.calcAndSetPx()

            let { widthPx: width,heightPx: height} = this.formModel
            this.$refs.designer.createNew({
                width,height
            })
        }
    },
    mounted() {
        this.handleChangePageSize()
    }
}
</script>

<style scoped>
::v-deep .page-form .el-input__inner{
    width: 100px;
}
</style>
