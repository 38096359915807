<template>
    <x-code-editor v-model="innerValue" :options="innerOption" v-on="$listeners"></x-code-editor>
</template>

<script>

import "codemirror/theme/monokai.css";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/hint/show-hint.css";

import "codemirror/mode/xml/xml"; // 代码高亮必须引入


// 折叠必须引入
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/fold/foldcode'
import 'codemirror/addon/fold/foldgutter'
import 'codemirror/addon/fold/brace-fold'
import 'codemirror/addon/fold/comment-fold'
import 'codemirror/addon/fold/xml-fold.js'

export default {
    name: "x-xml-editor",
    components: {

    },
    props: {
        value: String,
        options: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        let defaultOptions = {
            mode: 'application/xml',
            theme: "monokai",

            // 折叠
            foldGutter: true,
            lineWrapping: true,
            gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
        }
        return {
            innerValue: this.value,
            defaultOptions,
            innerOption: Object.assign(defaultOptions, this.options || {})
        }
    },

    watch: {
        value() {
            this.innerValue = this.value
        },
        options() {
            this.innerOption = Object.assign(this.defaultOptions, this.options || {})
        },
    }
}
</script>

<style scoped>

</style>
