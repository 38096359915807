import ocr_api20210707, * as $ocr_api20210707 from '@alicloud/ocr-api20210707';
// 依赖的模块可通过下载工程中的模块依赖文件或右上角的获取 SDK 依赖信息查看
import OpenApi, * as $OpenApi from '@alicloud/openapi-client';
import Util, * as $Util from '@alicloud/tea-util';


export default class Client {

    /**
     * 使用AK&SK初始化账号Client
     * @param accessKeyId
     * @param accessKeySecret
     * @return Client
     * @throws Exception
     */
    static createClient(accessKeyId, accessKeySecret) {
        let config = new $OpenApi.Config({
            // 必填，您的 AccessKey ID
            accessKeyId: accessKeyId,
            // 必填，您的 AccessKey Secret
            accessKeySecret: accessKeySecret,
        });
        // 访问的域名
        config.endpoint = `ocr-api.cn-hangzhou.aliyuncs.com`;
        return new ocr_api20210707(config);
    }

    static callApi(params = {}) {
        let {
            accessKeyId, accessKeySecret, action = '', request = {}, runtime = {}
        } = params

        let client = Client.createClient(accessKeyId, accessKeySecret);

        let requestClass = $ocr_api20210707[`${action}Request`]
        if (!requestClass) {
            throw new Error('action is error')
        }
        let method = client[`${uncapFirstCase(action)}WithOptions`]
        if (!method) {
            throw new Error('action is error')
        }

        let recognizeBasicRequest = new requestClass(request);
        let runtimeOptions = new $Util.RuntimeOptions(runtime);

        function uncapFirstCase(str) {
            return str.slice(0, 1).toLowerCase() + str.slice(1);
        }

        // https://help.aliyun.com/document_detail/442247.htm?spm=a2c4g.11186623.0.0.6e606cf09mjMwI
        // 正确： {body: {data: '{}',requestId: ''}, headers: {},statusCode: 200}
        // 错误：{"RequestId":"2BB768F9-6128-563C-9597-3C56409D0ECB","HostId":"ocr-api.cn-hangzhou.aliyuncs.com","Code":"illegalImageSize","Message":"The image size must not be less than 5px or greater than 8192px.","Recommend":"https://next.api.aliyun.com/troubleshoot?q=illegalImageSize&product=ocr-api"}
        return new Promise((resolve, reject) => {
            method.bind(client)(recognizeBasicRequest, runtimeOptions).then(resp => {
                let {body: {data, requestId}} = resp

                let result = {requestId,data}
                if(typeof data == 'string') {
                    try{
                        result.data = JSON.parse(data)
                    }catch (e) {

                    }
                }
                resolve(result.data)
            }).catch(err => {
                reject(err)
            })
        })
    }


    /*
        FileReader文档：https://developer.mozilla.org/en-US/docs/Web/API/FileReader
        buffer 文档： https://www.runoob.com/nodejs/nodejs-buffer.html


        调用示例，其中body需要注意，有几种办法获取，一种办法是从arraybuffer中读取，另外一种则是从base64字符串中读取（要去除data:image...部分）
        import Stream from '@alicloud/darabonba-stream';
        let dataBuffer = Buffer.from(arrayBuffer)
        let body = Stream.readFromBytes(dataBuffer)  ||  body = Stream.readFromFilePath('file_path')

        const base64 = base64Url.replace(/^data:image\/\w+;base64,/, "");
        let dataBuffer = Buffer.from(base64, 'base64')

        callApi({
            accessKeyId: 'LTAI5t5o2GMBwvn2RhLDejdS',
            accessKeySecret: 'jyOQoxsM8jzJ385Z3BzFK9pyLytvFx',
            action: 'RecognizeAdvanced',
            request: {
                // url: '',
                // readFromFilePath、readFromBytes、readFromString
                // body: Stream.readFromString("1111111111111111")
                body: Stream.readFromBytes(dataBuffer)
            },
            runtime: {}
        }).then(resp => {
            console.log(resp)
        }).catch(err => {
            console.log(err)
        })

    */
}
