// https://www.npmjs.com/package/jsbarcode

const JsBarcode = require('jsbarcode');
const {createCanvas, loadImage} = require('canvas')

const type = 'XBarCode'

let defaultOptions = {

}
const generateBarCode = (options) => {
    options = {...defaultOptions, ...options}


    let {text = ''} = options

    return new Promise((resolve, reject) => {
        let canvas = createCanvas();
        JsBarcode(canvas, text);

        let url = canvas.toDataURL()
        fabric.Image.fromURL(url, (image) => {
            resolve({ url,image, element: image._element})
        })
    })
}

const XBarCode = fabric.util.createClass(fabric.Image, {
    type,

    /**
     * 初始化
     * @param options
     */
    initialize(options = {}) {

        options = {...defaultOptions, ...options}
        if (!options.id) {
            this.id = `${type}-${new Date().getTime()}`
        }
        this.callSuper('initialize', options.element, options);
    },
    /**
     * 转为JSON对象
     */
    toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
            text: this.get('text'),
            id: this.get('id')
        });
    },
    /**
     * 渲染
     * @param ctx
     * @private
     */
    _render(ctx) {
        this.callSuper('_render', ctx);
    },


    reload() {
        let {text = ''} = this
        if (text == null || text == undefined) {
            return
        }
        generateBarCode(this).then(({url,image}) => {
            this.setSrc(url)
        })
    }
});
fabric.XBarCode = XBarCode
fabric.XBarCode.generateBarCode = generateBarCode

fabric.XBarCode.fromObject = (options, callback) => {
    // return fabric.Object._fromObject(type, object, callback);

    generateBarCode(options).then(({url, image, element}) => {
        options.element = element
        callback && callback(new fabric.XBarCode(options));
    })

}

export default XBarCode
