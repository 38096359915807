<template>
    <el-form ref="form" :model="formModel" label-width="80px" @submit.native.prevent>
        <el-form-item label="KeyId">
            <el-input v-model="formModel.accessKeyId" placeholder="请输入 accessKeyId" clearable></el-input>
        </el-form-item>
        <el-form-item label="KeySecret">
            <el-input v-model="formModel.accessKeySecret" placeholder="请输入 accessKeySecret" clearable></el-input>
        </el-form-item>
        <el-form-item label="Action">
            <el-select v-model="formModel.action" placeholder="请选择">
                <el-option-group
                    v-for="group in actionOptions"
                    :key="group.label"
                    :label="group.label">
                    <el-option
                        v-for="item in group.options"
                        :key="item.value"
                        :label="`${item.label}(${item.value})`"
                        :value="item.value">
                    </el-option>
                </el-option-group>
            </el-select>
        </el-form-item>

        <el-form-item>
            <el-button native-type="submit" type="primary" :disabled="!isValidImageData()" @click="handleRecognition"
                       :loading="loading">
                开始识别
            </el-button>
            <el-button @click="handleSaveConfig">保存配置</el-button>
        </el-form-item>

    </el-form>
</template>

<script>

import AliOcrClient from '@/utils/ocr/aliyun'
import Stream from '@alicloud/darabonba-stream';

export default {
    name: "ControlCenter",
    props: {
        imageData: {
            type: [String],
            required: false
        }
    },
    data() {
        return {
            loading: false,
            actionOptions: [
                {
                    label: '通用文字识别',
                    options: [
                        {value: 'RecognizeAdvanced', label: '全文识别高精版'},
                        {value: 'RecognizeHandwriting', label: '通用手写体识别'},
                        {value: 'RecognizeBasic', label: '电商图片文字识别'},
                        {value: 'RecognizeGeneral', label: '通用文字识别'},
                        {value: 'RecognizeTableOcr', label: '表格识别'},
                        {value: 'RecognizeHealthCode', label: '防疫健康码识别'},
                        {value: 'RecognizeTravelCard', label: '通信行程卡识别'},
                        {value: 'RecognizeDocumentStructure', label: '文档结构化识别'},
                    ]
                }
            ],
            formModel: {
                accessKeyId: '',
                accessKeySecret: '',
                action: 'RecognizeAdvanced',
            }
        }
    },
    methods: {
        callOcrApi() {
            const base64 = this.imageData.replace(/^data:image\/\w+;base64,/, "");
            let dataBuffer = Buffer.from(base64, 'base64')

            let {accessKeyId, accessKeySecret, action} = this.formModel
            return AliOcrClient.callApi({
                accessKeyId,
                accessKeySecret,
                action,
                request: {
                    // url: '',
                    // readFromFilePath、readFromBytes、readFromString
                    // body: Stream.readFromString("1111111111111111")
                    body: Stream.readFromBytes(dataBuffer)
                },
                runtime: {}
            })
        },
        isValidImageData() {
            return this.$strings.isNotBlank(this.imageData)
        },
        handleRecognition() {
            this.loading = true
            this.callOcrApi().then(res => {
                this.$emit('recognition-success', res)
                this.$message.success('识别成功')
            }).catch(err => {
                let {code, data, description, accessDeniedDetail, statusCode} = err
                this.$emit('recognition-error', data)
                this.$message.error(data.Message)
            }).finally(() => {
                this.loading = false
            })
            // this.$emit('recognition-success', JSON.parse(JSON.stringify(require('../data.json').data)))
        },
        handleSaveConfig() {
            localStorage.setItem('ocr_config', JSON.stringify(this.formModel))
            this.$message.success('保存成功')
        },
        readConfig() {
            let str = localStorage.getItem('ocr_config')
            if (this.$strings.isBlank(str)) {
                return
            }
            try {
                this.formModel = JSON.parse(str)
            } finally {

            }
        }
    },
    created() {
        this.readConfig()
    }
}
</script>

<style scoped>
/deep/ .el-select {
    width: 100%;
}
</style>
